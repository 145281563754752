import { ProductItemDataType } from '@global/constant/types';
import { setAsyncStorage, getAsyncStorage, removeItemAsyncStorage } from '../global/utils/asyncFun';
import { toast } from 'react-toastify';
import { RootState } from '../global/appState/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCartData,
  setCartCount,
  setCartCheckOutData,
  setRefundCheckOutData,
} from '../global/appState/slice/customCartSlice';
import { SUBMIT_ORDER, GET_REFUND, SUBMIT_ORDER_REFUND, OFFLINE_SYNC_ORDER } from '../global/constant/apiRoutes';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import useProduct from './useProduct';
import useOrg from './useOrg';
import moment from 'moment';
import useCategory from './useCategory';
import { calculateTotalPrice, checkIfDiscounted } from '../global/utils/discountCalculator';

const useCart = () => {
  const dispatch = useDispatch();
  const { apiCall } = useAxios();
  const { getOrgData, getShopData, getProfileData, profileData, org, shopData } = useOrg();
  const { cartData, cartCount, cartCheckOutData, refundCheckOutData }: any = useSelector(
    (state: RootState) => state.customCart,
  );
  const { allShopProductData }: any = useSelector((state: RootState) => state.customProduct);
  const { onSetPreloadState } = useDialogState();
  const { setShowProduct } = useCategory();
  const {
    getProductItem,
    getAllShopProduct,
    getAllProductNameGroup,
    productNameItemData,
    updateStockRemainingOnLocalDB,
    getAllProductOnLocalDB,
    syncAllShopProductOnLocalBD,
    getAllProductOnLocalDBByGroupNameId,
  } = useProduct();

  const getCheckOutData = async (cash = 0) => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    const profile = await getAsyncStorage('profile');
    const shop = await getAsyncStorage('shop');

    const item = cartData?.find((element: any) => element.id === cartCount);

    let val = item?.data?.map((data: any) => ({
      product_id: data?.product_id,
      quantity: data?.quantity,
      sub_total:
        data?.discount_price !== 0
          ? calculateTotalPrice(
              Number(data?.quantity),
              parseFloat(data?.price),
              Number(data?.discount_price),
              Number(data?.discount_unit),
            )
          : data.price_type == 'price'
            ? Number(data?.price) * data?.quantity
            : Number(data?.wholesale_price) * data?.quantity,
      sub_total_type:
        data?.discount_price !== 0
          ? checkIfDiscounted(
              Number(data?.quantity),
              parseFloat(data?.price),
              Number(data?.discount_price),
              Number(data?.discount_unit),
            )
          : data.price_type == 'price'
            ? 'price'
            : 'wholesale',
    }));

    const totalSubTotal = val?.reduce((accumulator: any, product: any) => {
      return accumulator + product.sub_total;
    }, 0);

    if (cash == 0) {
      let dataToSubmit = {
        orders: val,
        total: totalSubTotal,
        shop_id: shop?.id,
        cashier: profile?.id,
        payment_method: 0,
        change: 0,
      };
      dispatch(setCartCheckOutData(dataToSubmit));
    } else {
      if (cash >= totalSubTotal) {
        let changeVl = cash - totalSubTotal;
        let dataToSubmit = {
          orders: val,
          total: totalSubTotal,
          shop_id: shop?.id,
          cashier: profile?.id,
          payment_method: 1,
          change: changeVl,
        };
        dispatch(setCartCheckOutData(dataToSubmit));
      }
    }
  };

  const getCatData = async () => {
    const cartCountData = await getAsyncStorage('cartCount');
    dispatch(setCartCount(cartCountData));
    const cartData = await getAsyncStorage('cart');
    dispatch(setCartData(cartData));
    getCheckOutData();
  };

  const holdOrder = async () => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');

    let count = cartData?.length + 1;
    console.log('count', count);
    cartData?.push({ id: count, data: [] });
    setAsyncStorage('cart', cartData);
    setAsyncStorage('cartCount', count);
    getCatData();
    toast.info(`Order ${cartCount} is now on hold`, {
      position: 'top-right',
    });
  };

  const addToCart = async (data: ProductItemDataType, refundOrderId?: number) => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    console.log('data check cart=>', data);
    if (Array.isArray(data)) {
      let saveCartData = [
        {
          id: 1,
          refundOrderId: refundOrderId,
          data: data,
        },
      ];
      if (cartData == null && cartCount == null) {
        setAsyncStorage('cartCount', 1);
        setAsyncStorage('cart', saveCartData);
        toast.success('Refund items added!', {
          position: 'top-right',
        });
        getCatData();
      } else {
        let count = cartData?.length + 1;
        console.log('count', count);
        cartData?.push({ id: count, refundOrderId: refundOrderId, data: data });
        setAsyncStorage('cart', cartData);
        setAsyncStorage('cartCount', count);
        toast.success('Refund items added!', {
          position: 'top-right',
        });
        getCatData();
      }
    } else {
      if (cartData == null && cartCount == null) {
        if (data?.stock_remaining === 'Out of Stock') {
          toast.error(`${'item is out of stock!'}`, {
            position: 'top-right',
          });
        } else {
          let saveCartData = [
            {
              id: 1,
              data: [
                {
                  barcode: data.barcode,
                  is_single: data.is_single,
                  price: data.price,
                  product_category_id: data.product_category_id,
                  product_id: data.product_id,
                  product_name: data.product_name,
                  product_name_group_id: data.product_name_group_id,
                  stock_id: data.stock_id,
                  stock_remaining: data.stock_remaining,
                  quantity: data?.stock_value == 'kilogram' ? parseFloat('1.00').toFixed(2) : 1,
                  stock_value: data.stock_value,
                  discount_price: data.discount_price,
                  discount_unit: data.discount_unit,
                  wholesale_price: data.wholesale_price,
                  is_expired: data.is_expired,
                  expiry_date: data.expiry_date,
                  price_type: 'price',
                },
              ],
            },
          ];
          setAsyncStorage('cart', saveCartData);
          setAsyncStorage('cartCount', 1);
          toast.success('One item added to cart!', {
            position: 'top-right',
          });
          getCatData();
        }
      } else {
        let item = cartData?.find((element: any) => element?.id === cartCount)?.data;

        if (item !== undefined) {
          if (item.find((dataItem: any) => dataItem?.product_id == data?.product_id) && Array.isArray(data) == false) {
            if (item.find((dataItem: any) => dataItem?.product_id == data?.product_id)?.stock_value == 'pieces') {
              let newQty = item.find((dataItem: any) => dataItem?.product_id == data?.product_id)?.quantity + 1;
              setCartItemQty(data?.product_id, newQty);
            } else {
              toast.warning(`${'The Item already exist in cart, item is in Kilogram increase item QTY'}`, {
                position: 'top-right',
              });
            }
          } else if (data?.stock_remaining === 'Out of Stock') {
            toast.error(`${'item is out of stock!'}`, {
              position: 'top-right',
            });
          } else {
            let newData = {
              barcode: data.barcode,
              is_single: data.is_single,
              price: data.price,
              product_category_id: data.product_category_id,
              product_id: data.product_id,
              product_name: data.product_name,
              product_name_group_id: data.product_name_group_id,
              stock_id: data.stock_id,
              stock_remaining: data.stock_remaining,
              quantity: data?.stock_value == 'kilogram' ? parseFloat('1.00').toFixed(2) : 1,
              stock_value: data.stock_value,
              discount_price: data.discount_price,
              discount_unit: data.discount_unit,
              wholesale_price: data.wholesale_price,
              is_expired: data.is_expired,
              expiry_date: data.expiry_date,
              price_type: 'price',
            };

            item.push(newData);

            // making a copy of the cart data to replace it with the update data
            cartData = cartData.map((val: any) => {
              if (val.id === cartCount) {
                return { ...val, data: item }; // Replace the 'data' property
              }
              return val; // Return unchanged items
            });

            setAsyncStorage('cart', cartData);
            toast.success('One item added to cart!', {
              position: 'top-right',
            });
            getCatData();
          }
        }
      }
    }
  };

  const scanToAddToCart = async (barcodeValue: string) => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    const productData = [].concat.apply([], Object.values(allShopProductData));

    if (cartData == null || cartData == undefined) {
      console.log('here');
      const findProductByBarcode = () => {
        const product = productData?.find((item: any) => item?.barcode === barcodeValue);
        console.log('scaned =>', product);
        if (product) {
          return product;
        }
        return null;
      };
      if (findProductByBarcode() == null) {
        toast.error('Item not found!', {
          position: 'top-right',
        });
      } else {
        addToCart(findProductByBarcode());
      }
    } else {
      console.log('here too');
      let cartItem = cartData.find((element: any) => element?.id === cartCount)?.data;
      let mappedIds = cartItem.map((data: any) => data.product_id);
      const findProductByBarcode = () => {
        const product = productData?.filter((item: any) => item?.barcode === barcodeValue);
        console.log('scanned =>', product);
        if (product) {
          return product;
        }
        return null;
      };
      // Remove the objects from objectsArray that match idsToRemove
      const filteredArray = findProductByBarcode()?.filter((obj: any) => !mappedIds.includes(obj.product_id));
      // Pick the first object from the filtered array
      if (filteredArray.length > 0) {
        const firstObject = filteredArray[0];
        addToCart(firstObject);
      } else {
        // toast.error('Item already exist in cart, increase item QTY in cart!', {
        //   position: 'top-right',
        // });
        console.log('filteredArray[', findProductByBarcode());
        if (
          cartItem.find((dataItem: any) => dataItem?.product_id == findProductByBarcode()[0]?.product_id)
            ?.stock_value == 'pieces'
        ) {
          let newQty =
            cartItem.find((dataItem: any) => dataItem?.product_id == findProductByBarcode()[0]?.product_id)?.quantity +
            1;
          setCartItemQty(findProductByBarcode()[0]?.product_id, newQty);
        } else {
          toast.warning(`${'The Item already exist in cart, item is in Kilogram increase item QTY'}`, {
            position: 'top-right',
          });
        }
      }
    }
  };

  const removeFromCartItem = async (dataId: number) => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    const item = cartData?.find((element: any) => element.id === cartCount);
    if (item) {
      item.data = item.data.filter((dataItem: any) => dataItem.product_id !== dataId);
      setAsyncStorage('cart', cartData);
      toast.success('One item deleted from cart!', {
        position: 'top-right',
      });
      getCatData();
    }
  };

  const cancelOrder = async () => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    const item = cartData?.filter((element: any) => element?.id !== cartCount);
    const _cart = cartData?.filter((element: any) => element?.id == cartCount);
    if (cartCount != null || cartCount != undefined) {
      if (item?.length < 1) {
        removeItemAsyncStorage('cart');
        removeItemAsyncStorage('cartCount');
        if (_cart[0]?.refundOrderId != null || _cart[0]?.refundOrderId != undefined) {
          removeItemAsyncStorage('refundRealData');
        }
      } else {
        setAsyncStorage('cart', item);
        removeItemAsyncStorage('cartCount');
        if (_cart[0]?.refundOrderId != null || _cart[0]?.refundOrderId != undefined) {
          removeItemAsyncStorage('refundRealData');
        }
      }

      dispatch(setCartCheckOutData({}));
      toast.success(`Order ${cartCount} cleared`, {
        position: 'top-right',
      });
      getCatData();
    }
  };

  const switchOrder = async (id: number) => {
    setAsyncStorage('cartCount', id);
    getCatData();
  };

  const setCartItemQty = async (product_id: number, quantityVal: any) => {
    console.log('quantityVal', quantityVal);
    let refundRealData = await getAsyncStorage('refundRealData');
    let oldQty = refundRealData?.find((element: any) => element.product_id === product_id)?.quantity;
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    let item = cartData?.find((element: any) => element.id === cartCount);
    const productData = [].concat.apply([], Object.values(allShopProductData));
    console.log('oldQty', oldQty);
    console.log('oldQty 2', productData.find((data: any) => data?.product_id == product_id)?.stock_remaining);
    if (
      parseFloat(quantityVal) >
      parseFloat(productData.find((data: any) => data?.product_id == product_id)?.stock_remaining)
    ) {
      toast.error(`Qty inputted is more than item in stock remaining!`, {
        position: 'top-right',
      });
    } else if (
      parseFloat(quantityVal) > parseFloat(oldQty) &&
      item.data?.find((element: any) => element?.product_id === product_id)?.is_sold !== undefined
    ) {
      toast.error(`new Qty can be more than old Qty which is ${oldQty}! `, {
        position: 'top-right',
      });
    } else {
      const regex = /^[0-9]*\.?[0-9]+$/;
      console.log('h3');
      item.data.find((element: any) => element?.product_id === product_id).quantity = quantityVal;
      if (regex) {
        setAsyncStorage('cart', cartData);
        if (quantityVal == 0 || quantityVal == '0') {
          toast.warning(`item Qty is now 0! `, {
            position: 'top-right',
          });
        } else {
          toast.success(
            `${item.data.find((element: any) => element?.product_id === product_id).product_name} item Qty updated! `,
            {
              position: 'top-right',
            },
          );
        }

        getCatData();
      }
    }
  };

  const setPriceType = async (dataId: number, type: string) => {
    let cartData = await getAsyncStorage('cart');
    const cartCount = await getAsyncStorage('cartCount');
    const item = cartData?.find((element: any) => element.id === cartCount);
    if (item) {
      item.data.find((dataItem: any) => dataItem?.product_id === dataId).price_type = type;
      setAsyncStorage('cart', cartData);
      toast.success(
        `${item.data.find((element: any) => element?.product_id === dataId).product_name} is set to use ${type}`,
        {
          position: 'top-right',
        },
      );
      getCatData();
    }
  };

  const handlePrint = (receiptData: any) => {
    const printWindow = window.open('', '', 'height=600,width=400');
    printWindow.document.write('<html><head><title>Receipt</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
    @page {
        margin: 0; 
    }
    @font-face {
        font-family: 'Libre Barcode 39';
        src: url('./fonts/LibreBarcode39-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
   body {
        font-family: Arial, sans-serif;
        font-size: 10px;
        margin: 0;
        padding: 0;
    }
    .receipt {
        width: 100%;
        margin: 0;
        padding: 2;
  
    }
    h1, p, th, td {
        margin: 0;
        padding: 0;
    }
    h1 {
        font-size: 10px;
        font-weight: bold;
    }
    h3 {  
        font-size: 10px; 
        font-weight: bold;
    }
    p{ 
        margin: 0; 
        padding: 0; 
       margin-top: 20px  }
    .center {
        text-align: center;
    }
    .barcode {
        font-family: "Libre Barcode 39", cursive;
        font-size: 40px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
     td {
        text-align: left;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 10px; 
    }
    th {
        text-align: left;
        border-bottom: 1px solid #000;
        font-weight: bold;
        font-size: 8px; 
        padding-top: 20px;
    }
`);
    printWindow.document.write('</style>');
    // printWindow.document.write(
    //   '<link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet">',
    // );
    printWindow.document.write('</head><body>');
    printWindow.document.write('<div class="receipt">');
    printWindow.document.write(`<h1 style="text-align: center; font-size: 20px">${receiptData?.order?.shop_name}</h1>`);
    printWindow.document.write(
      `<h3 style="text-align: center; font-size: 10px">${receiptData.order?.address} ${receiptData?.order?.city}</h3>`,
    );
    printWindow.document.write('<hr />');
    printWindow.document.write(`<p><strong>Cashier:</strong> ${receiptData?.order?.cashier_name}</p>`);
    printWindow.document.write(
      `<p><strong>Date:</strong> ${moment(receiptData?.order?.created_at).format('YYYY-MM-DD')}</p>`,
    );
    printWindow.document.write(`<p><strong>Transaction ID:</strong> ${receiptData?.order?.id}</p>`);
    printWindow.document.write('<hr />');
    printWindow.document.write('<table>');
    printWindow.document.write(
      '<thead><tr><th>Item</th><th>Qty</th><th>Rate</th><th>Amount</th><th>Type</th></tr></thead>',
    );
    printWindow.document.write('<tbody>');
    receiptData?.order_products?.forEach((item: any) => {
      printWindow.document.write(
        `<tr><td>${item.name}</td><td>${item?.quantity}</td><td>${receiptData?.order?.currency_symbol}${item?.price}</td><td>${receiptData?.order?.currency_symbol}${item?.sub_total}</td><td>${item?.sub_total_type === 'price' ? 'normal' : item?.sub_total_type}</td></tr>`,
      );
    });
    printWindow.document.write('</tbody>');
    printWindow.document.write('</table>');
    printWindow.document.write('<hr />');
    printWindow.document.write(`<p><strong>Payment Method:</strong> ${receiptData?.order?.payment_type}</p>`);
    printWindow.document.write(
      `<p><strong>Change:</strong> ${receiptData?.order?.currency_symbol}${receiptData?.order?.change}</p>`,
    );
    printWindow.document.write(
      `<p><strong>Total:</strong> ${receiptData?.order?.currency_symbol}${receiptData?.order?.total}</p>`,
    );
    printWindow.document.write('<div class="center">');
    printWindow.document.write(`<p class="barcode">*${receiptData?.order?.barcode}*</p>`);
    printWindow.document.write(`<p style="margin-top: -5px"><strong>${receiptData?.order?.barcode}</strong></p>`);
    printWindow.document.write('<p class="center"><strong>Thank you for your purchase!</strong> </p>');
    printWindow.document.write('</div>');
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    // Ensure the print happens after the content is fully loaded
    printWindow.onload = function () {
      printWindow.print();
    };
  };

  const offlineSyncSubmittedOrderOnLocalDBToCloud = async () => {
    try {
      const resD = await window.electron.ipcRenderer.invoke('fetch-transaction');
      console.log('offlineSyncSubmittedOrderOnLocalDBToCloud', resD);
      if (resD.length > 0) {
        const res = await apiCall('POST', OFFLINE_SYNC_ORDER, resD);
        if (res) {
          await window.electron.ipcRenderer.invoke('clear-order-table');
          toast.info(`${res?.data?.message}`, {
            position: 'top-right',
          });
          syncAllShopProductOnLocalBD();
        }
      }
    } catch (error) {
      onSetPreloadState(false);
      console.log('submit refund error', error?.response?.data?.message);
      toast.error(`${error?.response?.data?.message}`, {
        position: 'top-right',
      });
    }
  };

  const submitOrder = async () => {
    onSetPreloadState(true);
    getOrgData();

    try {
      if (cartCheckOutData.orders.find((data: any) => data.quantity == 0)) {
        onSetPreloadState(false);
        toast.error(`Found an item with 0 qty`, {
          position: 'top-right',
        });
      } else {
        const res = await apiCall('POST', SUBMIT_ORDER, cartCheckOutData);
        console.log('order res', res?.data?.receipt);
        onSetPreloadState(false);
        cancelOrder();
        offlineSyncSubmittedOrderOnLocalDBToCloud();
        syncAllShopProductOnLocalBD();
        toast.success(`Order created`, {
          position: 'top-right',
        });
        handlePrint(res?.data?.receipt);
        // getAllProductOnLocalDBByGroupNameId(productNameItemData[0]?.product_name_group_id);
        setShowProduct('category');
      }
    } catch (error) {
      onSetPreloadState(false);
      console.log('submit refund error', error?.response?.data?.message);
      toast.error(`${error?.response?.data?.message}`, {
        position: 'top-right',
      });
    }
  };

  const offlinePrintReceipt = async (receiptData: any, profileData_: any, org_: any, shopData_: any) => {
    console.log('profileData==>', profileData_, 'orgData==>', org_, 'shopData==>', shopData_);
    const productData = [].concat.apply([], Object.values(allShopProductData));
    const printWindow = window.open('', '', 'height=600,width=400');
    printWindow.document.write('<html><head><title>Receipt</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
    @page {
        margin: 0; 
    }
    @font-face {
        font-family: 'Libre Barcode 39';
        src: url('./fonts/LibreBarcode39-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
   body {
        font-family: Arial, sans-serif;
        font-size: 10px;
        margin: 0;
        padding: 0;
    }
    .receipt {
        width: 100%;
        margin: 0;
        padding: 2;
  
    }
    h1, p, th, td {
        margin: 0;
        padding: 0;
    }
    h1 {
        font-size: 10px;
        font-weight: bold;
    }
    h3 {  
        font-size: 10px; 
        font-weight: bold;
    }
    p{ 
        margin: 0; 
        padding: 0; 
       margin-top: 20px  }
    .center {
        text-align: center;
    }
    .barcode {
        font-family: "Libre Barcode 39", cursive;
        font-size: 30px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
     td {
        text-align: left;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 10px; 
    }
    th {
        text-align: left;
        border-bottom: 1px solid #000;
        font-weight: bold;
        font-size: 8px; 
        padding-top: 20px;
    }
`);
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<div class="receipt">');
    printWindow.document.write(`<h1 style="text-align: center; font-size: 20px">${shopData_?.shop_name}</h1>`);
    printWindow.document.write(
      `<h3 style="text-align: center; font-size: 10px">${shopData_?.address} ${shopData_?.city}</h3>`,
    );
    printWindow.document.write('<hr />');
    printWindow.document.write(`<p><strong>Cashier:</strong> ${profileData_?.first_name} ${profileData_?.surname}</p>`);
    printWindow.document.write(`<p><strong>Date:</strong> ${moment(Date()).format('YYYY-MM-DD')}</p>`);
    printWindow.document.write(`<p><strong>Transaction ID:</strong> ${receiptData?.id}</p>`);
    printWindow.document.write('<hr />');
    printWindow.document.write('<table>');
    printWindow.document.write(
      '<thead><tr><th>Item</th><th>Qty</th><th>Rate</th><th>Amount</th><th>Type</th></tr></thead>',
    );
    printWindow.document.write('<tbody>');
    receiptData?.orders?.forEach((item: any) => {
      printWindow.document.write(
        `<tr><td>${productData.filter((data: any) => data.product_id == item.product_id)[0]?.product_name}</td><td>${item?.quantity}</td><td>${org_?.country?.currency_symbol} ${item?.sub_total / item?.quantity}</td><td>${org_?.country?.currency_symbol} ${item?.sub_total}</td><td>${item?.sub_total_type === 'price' ? 'normal' : item?.sub_total_type}</td></tr>`,
      );
    });
    printWindow.document.write('</tbody>');
    printWindow.document.write('</table>');
    printWindow.document.write('<hr />');
    printWindow.document.write(
      `<p><strong>Payment Method:</strong> ${receiptData?.payment_method === 0 ? 'Card' : 'Cash'}</p>`,
    );
    printWindow.document.write(
      `<p><strong>Change:</strong> ${org_?.country?.currency_symbol} ${receiptData?.change}</p>`,
    );
    printWindow.document.write(
      `<p><strong>Total:</strong> ${org_?.country?.currency_symbol} ${receiptData?.total}</p>`,
    );
    printWindow.document.write('<div class="center">');
    printWindow.document.write(`<p class="barcode">*${receiptData?.barcode}*</p>`);
    printWindow.document.write(`<p style="margin-top: -5px"><strong>${receiptData?.barcode}</strong></p>`);
    printWindow.document.write('<p class="center"><strong>Thank you for your purchase!</strong> </p>');
    printWindow.document.write('</div>');
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    // Ensure the print happens after the content is fully loaded
    printWindow.onload = function () {
      printWindow.print();
    };
  };

  const offlineSubmitOrdeROnLocalDB = async () => {
    try {
      onSetPreloadState(true);
      if (cartCheckOutData.orders.find((data: any) => data.quantity == 0)) {
        onSetPreloadState(false);
        toast.error(`Found an item with 0 qty`, {
          position: 'top-right',
        });
      } else {
        const resD = await window.electron.ipcRenderer.invoke('insert-transaction', cartCheckOutData);
        console.log('offlineSubmitOrdeROnLocalDB resD=>', resD);
        updateStockRemainingOnLocalDB(cartCheckOutData.orders);
        setShowProduct('category');
        cancelOrder();
        toast.success(`Order created in offline mode`, {
          position: 'top-right',
        });
        let receipt = await window.electron.ipcRenderer.invoke('fetch-transaction-by-id', resD.transactionID);
        if (receipt) {
          console.log('receipt offline', receipt);
          console.log('offline local=>', receipt, profileData, org, shopData);
          await offlinePrintReceipt(receipt, profileData, org, shopData);
          onSetPreloadState(false);
        }
      }
    } catch (error) {
      onSetPreloadState(false);
      console.log('offlineSubmitOrdeROnLocalDB  error', error);
      toast.error(`error`, {
        position: 'top-right',
      });
    }
  };

  const getOrdeROnLocalDB = async () => {
    onSetPreloadState(true);
    try {
      const resD = await window.electron.ipcRenderer.invoke('fetch-transaction');
      console.log('fetch OrdeROnLocalDB=>', resD);
      onSetPreloadState(false);
    } catch (error) {
      onSetPreloadState(false);
      console.log('offlineSubmitOrdeROnLocalDB  error', error);
      toast.error(`error`, {
        position: 'top-right',
      });
    }
  };

  const getRefundData = async (barcode: any) => {
    let refundRealData = await getAsyncStorage('refundRealData');
    onSetPreloadState(true);
    if (refundRealData != undefined || refundRealData != null) {
      onSetPreloadState(false);
      toast.error(`Sorry there is a refund in process already clear it out frist`, {
        position: 'top-right',
      });
      return;
    }
    try {
      const res = await apiCall('GET', `${GET_REFUND}?barcode=${barcode}`);
      addToCart(
        res.data.data.order_products.filter((data: any) => data.quantity !== 0),
        res.data.data.order.id,
      );
      setAsyncStorage('refundRealData', res.data.data.order_products);
      onSetPreloadState(false);
    } catch (error) {
      console.log('GET_REFUND  error', error);
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
      });
      onSetPreloadState(false);
    }
  };

  const setRefundData = async (data?: any) => {
    let refundRealData = await getAsyncStorage('refundRealData');
    const shop = await getAsyncStorage('shop');
    console.log('=x=', data);
    const _sub_total = parseFloat(data?.price) * data?.quantity;
    let val = {
      product_id: data?.product_id,
      quantity: data?.quantity,
      sub_total: _sub_total?.toFixed(2),
      unit_price: data?.price,
    };

    console.log('=x===>', val);

    let _product = [...refundCheckOutData?.products];

    _product.push(val);

    if (data?.is_single === 0) {
      const map = new Map();
      _product.forEach((obj) => {
        map.set(obj.product_id, obj);
      });

      // Converting the Map values back to an array
      _product = Array.from(map.values());
    }

    console.log('_product', _product);
    const totalSubTotal = _product?.reduce((accumulator: any, product: any) => {
      return accumulator + parseFloat(product.sub_total);
    }, 0);

    const cartTotal = refundRealData?.reduce((accumulator: any, product: any) => {
      return accumulator + parseFloat(product.sub_total);
    }, 0);

    console.log('cartTotal ', cartTotal);
    console.log('totalSubTotal', totalSubTotal);

    let dataToSubmit = {
      products: _product,
      total: cartTotal - totalSubTotal,
      shop_id: shop?.id,
      payment_method: 0,
      order_id: cartData?.find((element: any) => element?.id === cartCount)?.refundOrderId,
      change: 0,
    };

    console.log('dataToSubmit', dataToSubmit);
    dispatch(setRefundCheckOutData(dataToSubmit));
    getCatData();
  };

  const undoRefundData = async (dataId: any) => {
    let refundRealData = await getAsyncStorage('refundRealData');
    const shop = await getAsyncStorage('shop');

    let _product = refundCheckOutData?.products.filter((dataItem: any) => dataItem.product_id !== dataId);

    console.log(
      '_product =>>',
      refundCheckOutData?.products.filter((dataItem: any) => dataItem.product_id !== dataId),
    );

    const totalSubTotal = _product?.reduce((accumulator: any, product: any) => {
      return accumulator + parseFloat(product.sub_total);
    }, 0);

    const cartTotal = refundRealData?.reduce((accumulator: any, product: any) => {
      return accumulator + parseFloat(product.sub_total);
    }, 0);

    let dataToSubmit = {
      products: _product,
      total: cartTotal - totalSubTotal,
      shop_id: shop?.id,
      payment_method: 0,
      order_id: cartData?.find((element: any) => element?.id === cartCount)?.refundOrderId,
      change: 0,
    };
    console.log('dataToSubmit', dataToSubmit);
    dispatch(setRefundCheckOutData(dataToSubmit));
    getCatData();
  };

  const processRefund = async () => {
    onSetPreloadState(true);
    try {
      const res = await apiCall('POST', SUBMIT_ORDER_REFUND, refundCheckOutData);
      console.log('order res', res?.data?.receipt);
      onSetPreloadState(false);
      cancelOrder();
      dispatch(
        setRefundCheckOutData({
          products: [],
        }),
      );
      getAllShopProduct();
      removeItemAsyncStorage('refundRealData');
      getProductItem(productNameItemData[0]?.product_name_group_id);
      toast.success(`Order created`, {
        position: 'top-right',
      });
      handlePrint(res?.data?.receipt);
      dispatch(
        setRefundCheckOutData({
          products: [],
        }),
      );
    } catch (error) {
      onSetPreloadState(false);
      console.log('submit refund error', error?.response?.data?.message);
      toast.error(`${error?.response?.data?.message}`, {
        position: 'top-right',
      });
    }
  };

  return {
    addToCart,
    getCatData,
    removeFromCartItem,
    cancelOrder,
    holdOrder,
    switchOrder,
    scanToAddToCart,
    getCheckOutData,
    setCartItemQty,
    handlePrint,
    submitOrder,
    getRefundData,
    setRefundData,
    undoRefundData,
    processRefund,
    offlineSubmitOrdeROnLocalDB,
    getOrdeROnLocalDB,
    offlineSyncSubmittedOrderOnLocalDBToCloud,
    setPriceType,
    cartData,
    cartCount,
    cartCheckOutData,
    refundCheckOutData,
  };
};

export default useCart;

export const calculateTotalPrice = (productQuantity: any, normalPrice: any, discountPrice: any, discountUnit: any) => {
  // Ensure all values are numbers
  productQuantity = Number(productQuantity) || 0;
  normalPrice = parseFloat(normalPrice) || 0;
  discountPrice = discountPrice !== 0 ? parseFloat(discountPrice) : null;
  discountUnit = Number(discountUnit) || 0;

  // Validate inputs
  if (productQuantity <= 0 || normalPrice <= 0 || discountUnit <= 0 || discountPrice === null) {
    return 'Invalid input';
  }

  // Calculate the number of times discount applies
  let fullDiscountCycles = Math.floor(productQuantity / discountUnit);
  let remainingUnits = productQuantity % discountUnit;

  // Apply discount price for full discount cycles
  let discountedPrice = fullDiscountCycles * discountPrice;

  // Apply normal price for leftover products
  let normalPriceAmount = remainingUnits * normalPrice;

  return discountedPrice + normalPriceAmount;
};

export const checkIfDiscounted = (productQuantity: any, normalPrice: any, discountPrice: any, discountUnit: any) => {
  // Ensure all values are numbers
  productQuantity = Number(productQuantity) || 0;
  normalPrice = parseFloat(normalPrice) || 0;
  discountPrice = discountPrice !== 0 ? parseFloat(discountPrice) : null;
  discountUnit = Number(discountUnit) || 0;

  // Validate inputs
  if (productQuantity <= 0 || normalPrice <= 0 || discountUnit <= 0 || discountPrice === null) {
    return 'Invalid input';
  }

  // Calculate the number of times discount applies
  let fullDiscountCycles = Math.floor(productQuantity / discountUnit);
  let remainingUnits = productQuantity % discountUnit;

  // Apply discount price for full discount cycles
  let discountedPrice = fullDiscountCycles * discountPrice;

  // Apply normal price for leftover products
  let normalPriceAmount = remainingUnits * normalPrice;

  // Calculate total price
  let totalPrice = discountedPrice + normalPriceAmount;

  // Return string based on whether a discount was applied
  if (discountedPrice > 0) {
    return 'discount';
  } else {
    return 'price'
  }
};

import { FC, useState, useRef, useEffect } from 'react';
import { ScrollView, View, Text, Image, TouchableOpacity, TextInput, Keyboard } from 'react-native';
import Layout from '../../components/layout';
import {
  CustomButton,
  CustomCartProductCard,
  CustomNumberKeyboard,
  CustomModal,
  CustomInput,
  ProductContainer,
} from '../../components';
import useCustomKeyBoard from '../../hooks/useCustomKeyBoard';
import { CartScreenProps } from '../../navigation/appNavigation';
import { appColors } from '../../global/constant/colors';
import useCart from '../../hooks/useCart';
import BarcodeReader from 'react-barcode-reader';
import { getAsyncStorage } from '../../global/utils/asyncFun';
import useCategory from '../../hooks/useCategory';
import useOrg from '../../hooks/useOrg';
import useDialogState from '../../hooks/useDialogState';

const CartScreen: FC<CartScreenProps> = ({ navigation }) => {

  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => {
    setVisible(false);
  };
  const {
    cartData,
    cartCount,
    cartCheckOutData,
    refundCheckOutData,
    cancelOrder,
    holdOrder,
    switchOrder,
    getCatData,
    scanToAddToCart,
    getCheckOutData,
    submitOrder,
    setCartItemQty,
    getRefundData,
    processRefund,
    offlineSubmitOrdeROnLocalDB,
    getOrdeROnLocalDB,
  } = useCart();
  const { onSetPreloadState } = useDialogState();
  const { categoryData, getAllCategoryOnLocalDB } = useCategory();
  const { getOrgData, org, getShopData, getProfileData } = useOrg();
  const [modeOfPaymentType, setModeOfPaymentType] = useState('');
  const [checkOutModalState, setCheckOutModalState] = useState(false);
  const [qtyModalState, setQtyOutModalState] = useState(false);
  const [cashAmount, setCashAmount] = useState('');
  const [cashErrorShow, setCashError] = useState(false);
  const [refundBarcodeNumber, setRefundBarcodeNumber] = useState('');
  const [itemToUpdate, setItemToUpdate] = useState<any>({});
  const [inputState, setInputState] = useState<any>('');

  const onHideQtyOutModalState = () => {
    setQtyOutModalState(false);
  };

  const onShowQtyOutModalState = (val: any) => {
    setQtyOutModalState(true);
    setItemToUpdate(val);
    setInputState(`${val.quantity}`);
  };

  const onSubmitRefundBarcode = () => {
    hideModal();
    getRefundData(refundBarcodeNumber);
  };

  const onQtyKeySet = async (text: any) => {
    let _cartData = await getAsyncStorage('cart');
    const _cartCount = await getAsyncStorage('cartCount');
    let _cartItemID = await getAsyncStorage('_cartItemID');
    const cartVal = _cartData?.find((element: any) => element?.id === _cartCount);
    const stock_value = cartVal.data.find((element: any) => element?.product_id === _cartItemID).stock_value;
    if (text === '-') {
      if (stock_value == 'kilogram') {
        setInputState((prevString: any) => prevString.slice(0, -1));
      } else {
        setInputState((prevString: any) => prevString.slice(0, -1));
      }
    } else {
      if (stock_value == 'kilogram') {
        setInputState((prevString: any) => prevString + text);
      } else {
        const regex = /^[0-9]+$/;
        if (regex.test(`${text}`)) {
          setInputState((prevString: any) => prevString + text);
        }
      }
    }
  };

  const onUpdateItemQty = async () => {
    let _cartData = await getAsyncStorage('cart');
    const _cartCount = await getAsyncStorage('cartCount');
    let _cartItemID = await getAsyncStorage('_cartItemID');
    const cartVal = _cartData?.find((element: any) => element?.id === _cartCount);
    const stock_value = cartVal.data.find((element: any) => element?.product_id === _cartItemID).stock_value;
    setCartItemQty(_cartItemID, stock_value == 'kilogram' ? parseFloat(inputState).toFixed(2) : Number(inputState));
    onHideQtyOutModalState();
  };

  const hideModalCheckOutModalState = () => {
    setCheckOutModalState(false);
    setModeOfPaymentType('');
    setCashError(false);
    setCashAmount('');
  };

  const onKeyCashAmount = (val: string) => {
    if (val === '-') {
      setCashAmount(cashAmount.slice(0, -1)); // Remove last character
      getCheckOutData(Number(cashAmount.slice(0, -1)));
    } else {
      setCashAmount(cashAmount.concat('', val));
      getCheckOutData(Number(cashAmount.concat('', val)));
      if (cartCheckOutData?.total > Number(cashAmount.concat('', val))) {
        setCashError(true);
      } else {
        setCashError(false);
      }
    }
  };

  const onCashAmount = (val: string) => {
    setCashAmount(val);
    getCheckOutData(Number(val));
    if (cartCheckOutData?.total > Number(val)) {
      setCashError(true);
    } else {
      setCashError(false);
    }
  };

  useEffect(() => {
    getAllCategoryOnLocalDB();
    getOrgData();
    getShopData();
    getProfileData();
  }, []);


  useEffect(() => {
    getCatData();
  }, []);

  const getCartSingItemById = (id: number) => {
    const item = cartData?.find((element: any) => element?.id === id);
    return item ? item['data'] : [];
  };

  const handleScan = (result: any) => {
    Keyboard.dismiss();
    const activeElement = document.activeElement;
    if (activeElement && (activeElement as HTMLInputElement).blur) {
      (activeElement as HTMLInputElement).blur(); // Safely call blur() on the active element
    }
    if (result) {
      visible ? getRefundData(result) : scanToAddToCart(result);
      console.log('scan result', result);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const onSubmitOrder = async () => {
    hideModalCheckOutModalState();
    onSetPreloadState(true);

    try {
      const reachable = await window?.electron?.ipcRenderer.invoke('check-internet');
      if (reachable == true) {
        onSetPreloadState(false);
        submitOrder();
      } else {
        onSetPreloadState(false);
        offlineSubmitOrdeROnLocalDB();
        // getOrdeROnLocalDB();
      }
      console.log('isConnected', reachable);
    } catch (error) {
      onSetPreloadState(false);
      console.log('isConnected error', error);
    }
  };

  const OrderList = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <TouchableOpacity
          onPress={cancelOrder}
          style={{
            backgroundColor: 'rgba(183, 35, 0, 1)',
            padding: 15,
            borderRadius: 10,
            width: '20%',
            marginRight: 10,
          }}
        >
          <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700' }}>Cancel Order</Text>
        </TouchableOpacity>

        {cartData?.map((data: any) => (
          <CustomButton
            width={'20%'}
            bntType="secondary"
            mode={cartCount == data.id ? 'contained' : 'text'}
            text={`Order${data.id}`}
            onPress={() => switchOrder(data.id)}
          />
        ))}
      </div>
    );
  };

  const Footer = () => {
    return (
      <>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={{ color: 'rgba(74, 74, 74, 1)', fontSize: 20, fontWeight: '800' }}>Total</Text>
          <Text style={{ color: 'rgba(74, 74, 74, 1)', fontSize: 20, fontWeight: '800' }}>
            {org?.country?.currency_symbol}
            {cartData?.find((data: any) => data.id == cartCount)?.data[0]?.is_sold == undefined
              ? cartCheckOutData?.total?.toFixed(2)
              : refundCheckOutData?.products?.length < 1
                ? cartCheckOutData?.total?.toFixed(2)
                : refundCheckOutData.total?.toFixed(2)}
          </Text>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 10, width: '100%', justifyContent: 'center' }}>
          <View style={{ marginHorizontal: 5, width: '50%' }}>
            <CustomButton
              padding={10}
              width={'100%'}
              bntType="primary"
              mode="contained"
              text={'Hold'}
              onPress={holdOrder}
            />
          </View>
          <View style={{ marginHorizontal: 5, width: '50%' }}>
            <CustomButton
              padding={10}
              width={'100%'}
              bntType="secondary"
              mode="contained"
              text={
                cartData?.find((data: any) => data.id == cartCount)?.data[0]?.is_sold == undefined
                  ? 'CheckOut Order'
                  : 'Process Refund'
              }
              onPress={() =>
                cartData?.find((data: any) => data.id == cartCount)?.data[0]?.is_sold == undefined
                  ? setCheckOutModalState(true)
                  : processRefund()
              }
            />
          </View>
        </View>
      </>
    );
  };

  // refundBarcodeNumber, productNameGroupData, categoryData, allShopProductData
  return (
    <div>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <Layout
        setRefundOptionModal={showModal}
        leftSideContent={
          <View style={{ marginTop: 30, paddingHorizontal: 30, height: '70%' }}>
            <ProductContainer />
          </View>
        }
        headerContent={<OrderList />}
        footerContent={cartData == null || cartData == undefined ? <></> : <Footer />}
        mainContent={
          <>
            {getCartSingItemById(cartCount)?.map((data: any, index: any) => (
              <div key={index}>
                <CustomCartProductCard
                  onShowQtyOutModalState={onShowQtyOutModalState}
                  setInputState={setInputState}
                  inputState={inputState}
                  data={data}
                />
              </div>
            ))}
          </>
        }
      />
      <CustomModal
        content={
          <div style={{ paddingBottom: '50px' }}>
            <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', marginTop: '-50px' }}>
              {modeOfPaymentType == 'cash' ? 'How much is the cash?' : ' Choose method of payment'}
            </p>
            {modeOfPaymentType == '' && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 100,
                  paddingRight: 100,
                }}
              >
                <View style={{ marginHorizontal: 5, width: '50%' }}>
                  <CustomButton
                    padding={10}
                    width={'100%'}
                    bntType="primary"
                    mode="contained"
                    text={'Cash Payment'}
                    onPress={() => setModeOfPaymentType('cash')}
                  />
                </View>
                <View style={{ marginHorizontal: 5, width: '50%' }}>
                  <CustomButton
                    padding={10}
                    width={'100%'}
                    bntType="secondary"
                    mode="contained"
                    text={'Card Payment'}
                    onPress={() => onSubmitOrder()}
                  />
                </View>
              </View>
            )}
            {modeOfPaymentType == 'cash' && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <View style={{ width: '50%', backgroundColor: appColors.secondary, padding: 30, borderRadius: 20 }}>
                  <CustomNumberKeyboard setFun={onKeyCashAmount} />
                </View>

                <View style={{ paddingLeft: 30, paddingRight: 30, width: '50%' }}>
                  <CustomInput
                    value={cashAmount}
                    placeholder="Cash Amount"
                    onChangeText={(e) => onCashAmount(e)}
                    inputMode="text"
                  />
                  {cashErrorShow && (
                    <p style={{ color: 'red' }}>Cash amount is lower then the amount of items in cart</p>
                  )}
                  {!cashErrorShow && cashAmount !== '' && (
                    <View style={{ marginTop: 30 }}>
                      <CustomButton
                        padding={10}
                        width={'100%'}
                        bntType="primary"
                        mode="contained"
                        text={'Proceed'}
                        onPress={() => onSubmitOrder()}
                      />
                    </View>
                  )}

                  <View>
                    <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                      Customer Balance:{' '}
                      <span style={{ fontWeight: 'bold', fontSize: '30px' }}>
                        {org?.country?.currency_symbol}
                        {Number(cartCheckOutData?.change).toFixed(2)}{' '}
                      </span>{' '}
                    </p>
                  </View>
                </View>
              </div>
            )}
          </div>
        }
        marginLeft={100}
        marginRight={100}
        hideModal={hideModalCheckOutModalState}
        visible={checkOutModalState}
      />

      <CustomModal
        content={
          <div style={{ paddingBottom: '50px' }}>
            <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold', marginTop: '-50px' }}>
              {itemToUpdate?.product_name}
            </p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <View style={{ width: '50%', backgroundColor: appColors.secondary, padding: 30, borderRadius: 20 }}>
                <CustomNumberKeyboard setFun={onQtyKeySet} />
              </View>

              <View style={{ paddingLeft: 30, paddingRight: 30, width: '50%' }}>
                <CustomInput
                  value={inputState}
                  placeholder=""
                  onChangeText={(e) => setInputState(e)}
                  inputMode="text"
                />

                <View style={{ marginTop: 30 }}>
                  <CustomButton
                    padding={10}
                    width={'100%'}
                    bntType="primary"
                    mode="contained"
                    text={'Update'}
                    onPress={() => onUpdateItemQty()}
                  />
                </View>

                <View></View>
              </View>
            </div>
          </div>
        }
        marginLeft={100}
        marginRight={100}
        hideModal={onHideQtyOutModalState}
        visible={qtyModalState}
      />
      <CustomModal
        content={
          <>
            <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>
              Enter Receipt Barcode Number or Scan Receipt Barcode
            </p>
            <View style={{ paddingLeft: 30, paddingRight: 30, width: '100%' }}>
              <CustomInput
                value={refundBarcodeNumber}
                placeholder="Transaction Barcode Number"
                onChangeText={(e) => setRefundBarcodeNumber(e)}
                inputMode="text"
              />
              <div style={{ marginTop: '15px' }}>
                <CustomButton
                  padding={10}
                  width={'100%'}
                  bntType="primary"
                  mode="contained"
                  text={'Submit'}
                  onPress={onSubmitRefundBarcode}
                />
              </div>
            </View>
          </>
        }
        marginLeft={450}
        marginRight={450}
        hideModal={hideModal}
        visible={visible}
      />
    </div>
  );
};

export default CartScreen;

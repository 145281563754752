import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import React, { useEffect } from 'react';
import useOrg from '../../hooks/useOrg';
import { appColors } from '../../global/constant/colors';
import { setAsyncStorage } from '../../global/utils/asyncFun';
import { useNavigation } from '@react-navigation/native';

const Shops = () => {
  const { getOrgData, org } = useOrg();
  const navigation: any = useNavigation();

  useEffect(() => {
    getOrgData();
  }, []);

  const onSelectShop = (val: any) => {
    setAsyncStorage('shop', val);
    navigation.replace('app', { screen: 'lobbyScreen' });
  };

  return (
    <div
      style={{
        backgroundColor: appColors.secondary,
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
      }}
    >
      <View style={{ paddingRight: 100, paddingLeft: 100 }}>
        <FlatList
          contentContainerStyle={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 40,
          }}
          keyExtractor={(index: any) => index.id}
          numColumns={6}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <TouchableOpacity style={{ margin: 10, borderRadius: 15 }} onPress={() => onSelectShop(item)}>
              <View
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  height: 130,
                  width: 200,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 5,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    color: appColors.primary,
                    fontSize: 15,
                    fontWeight: '600',
                    textTransform: 'uppercase',
                  }}
                >
                  {item.shop_name}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          data={org?.shops}
        />
      </View>
    </div>
  );
};

export default Shops;

import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import React, { FC, useState } from 'react';
import { AddProductByScannerScreenProps } from 'navigation/appNavigation';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import BarcodeReader from 'react-barcode-reader';
import { appColors } from '../../global/constant/colors';
import { Card } from 'react-native-paper';
import { ReactBarcode } from 'react-jsbarcode';
import { CustomInput, CustomButton, CustomImageUpload, CustomDialog } from '../../components';
import useProduct from '../../hooks/useProduct';

const AddProductByScannerScreen: FC<AddProductByScannerScreenProps> = ({ navigation, route }) => {
  const { productNameGroupParamsData }: any = route.params;
  const { createProductItemByScanner } = useProduct();

  const [productData, setProductData] = useState({
    name: productNameGroupParamsData.product_name_group,
    product_name_group_id: productNameGroupParamsData?.id,
    category_id: productNameGroupParamsData.product_category_id,
    description: '',
    is_single: 1,
    stock: '',
    barcode: [],
    cost_per_unit: '',
    expiry_date: '',
    wholesale_price: '',
    discount_unit: '',
    discount_price: '',
  });

  const handleScan = (result: any) => {
    if (result && productData.barcode.length < 1) {
      setProductData((prevData) => ({
        ...prevData,
        barcode: [...prevData.barcode, result],
      }));
      console.log('scan result', result);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };
  const onClearFun = () => {
    setProductData({
      ...productData,
      name: productNameGroupParamsData.product_name_group,
      description: '',
      barcode: [],
      stock: '',
      cost_per_unit: '',
      expiry_date: '',
      wholesale_price: '',
      discount_unit: '',
      discount_price: '',
    });
  };
  const onSubmit = () => {
    createProductItemByScanner(
      {
        name: productData.name,
        product_name_group_id: productData.product_name_group_id,
        category_id: productData.category_id,
        description: productData.description,
        is_single: 1,
        stock: Number(productData.stock),
        barcode: productData.barcode,
        cost_per_unit: productData.cost_per_unit,
        expiry_date: productData.expiry_date,
        wholesale_price: Number(productData.wholesale_price),
        discount_unit: Number(productData.discount_unit),
        discount_price: Number(productData.discount_price),
      },
      onClearFun,
    );
  };

  return (
    <>
      <AdminLayout
        content={
          <div style={{ height: '100%' }}>
            <View>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <View style={{ flexDirection: 'row' }}>
                  <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                    <Path
                      d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                      fill="#1E1E1E"
                    />
                  </Svg>
                  <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>ADD PRODUCT</Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{ marginTop: 40 }}>
              <FlatList
                ListHeaderComponentStyle={{ width: '100%' }}
                ListHeaderComponent={
                  <>
                    <Card
                      style={{
                        backgroundColor: '#ffffff',
                        height: 400,
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 5,
                        marginRight: 5,
                        marginLeft: 5,
                      }}
                      mode="elevated"
                    >
                      <Card.Content>
                        <>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.name}
                                placeholder="Product Name"
                                onChangeText={(e) => setProductData({ ...productData, name: e })}
                                inputMode="text"
                                editable={false}
                                label="Product Name"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productNameGroupParamsData.price}
                                placeholder="Product Price"
                                onChangeText={(e) => {}}
                                inputMode="text"
                                editable={false}
                                label="Product Price"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.stock}
                                placeholder="Stock"
                                onChangeText={(e) => setProductData({ ...productData, stock: e })}
                                inputMode="text"
                                label="stock"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.cost_per_unit}
                                placeholder="Cost Price Per Unit"
                                onChangeText={(e) => setProductData({ ...productData, cost_per_unit: e })}
                                inputMode="text"
                                label="Cost Price Per Unit"
                              />
                            </View>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: -20 }}>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.cost_per_unit}
                                placeholder="Cost Price Per Unit"
                                onChangeText={(e) => setProductData({ ...productData, cost_per_unit: e })}
                                inputMode="text"
                                label="Cost Price Per Unit"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.wholesale_price}
                                placeholder="Wholesale Price (Optional)"
                                onChangeText={(e) => setProductData({ ...productData, wholesale_price: e })}
                                inputMode="text"
                                label="Wholesale Price"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.discount_price}
                                placeholder="Discount Price  (Optional)"
                                onChangeText={(e) => setProductData({ ...productData, discount_price: e })}
                                inputMode="text"
                                label="Discount Price"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.discount_unit}
                                placeholder="Discount unit  (Optional)"
                                onChangeText={(e) => setProductData({ ...productData, discount_unit: e })}
                                inputMode="text"
                                label="Discount Unit"
                              />
                            </View>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: -20 }}>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <CustomInput
                                value={productData.description}
                                placeholder="Product Description (Optional)"
                                onChangeText={(e) => setProductData({ ...productData, description: e })}
                                inputMode="text"
                                label="Product Description"
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              <Text
                                style={{
                                  color: '#271E00',
                                  fontSize: 17,
                                  fontWeight: '400',
                                  height: 24,
                                  lineHeight: 24,
                                }}
                              >
                                Expiry Date
                              </Text>
                              <input
                                onChange={(e: any) => setProductData({ ...productData, expiry_date: e.target.value})}
                                type="date"
                                style={{
                                  height: 25,
                                  borderWidth: 0.3,
                                  padding: 13,
                                  borderColor: '#271E00',
                                  borderRadius: 10,
                                  color: '#3D3D3D',
                                  fontSize: 14,
                                  fontWeight: '500',
                                  backgroundColor: '#efeded',
                                }}
                              />
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                              {productData?.barcode.length >= 1 && productData?.stock.length >= 1 && (
                                <View style={{  height: '100%', marginBottom: 50, marginTop: 30 }}>
                                  <CustomButton
                                    fontSize={14}
                                    padding={5}
                                    width={'100%'}
                                    bntType="primary"
                                    mode="contained"
                                    text="SAVE"
                                    onPress={onSubmit}
                                  />
                                </View>
                              )}
                            </View>
                            <View style={{ width: '20%', height: '100%', marginBottom: 50, marginRight: 20 }}></View>
                          </View>
                        </>
                      </Card.Content>
                    </Card>

                    <BarcodeReader onError={handleError} onScan={handleScan} />
                  </>
                }
                contentContainerStyle={{
                  justifyContent: 'space-between',
                  margin: 10,
                }}
                keyExtractor={(item: any) => item}
                numColumns={4}
                ListFooterComponent={
                  <>
                    {productData.barcode.length > 0 && (
                      <TouchableOpacity
                        onPress={() => setProductData({ ...productData, barcode: [] })}
                        style={{
                          backgroundColor: 'rgba(183, 35, 0, 1)',
                          padding: 15,
                          borderRadius: 10,
                          width: 320,
                          marginRight: 10,
                        }}
                      >
                        <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700' }}>Remove</Text>
                      </TouchableOpacity>
                    )}
                  </>
                }
                renderItem={({ item }) => (
                  <Card
                    style={{
                      backgroundColor: '#ffffff',
                      height: 150,
                      width: 320,
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                    mode="elevated"
                  >
                    <Card.Content>
                      <View style={{ alignItems: 'center' }}>
                        <View style={{ marginLeft: 10 }}>
                          <Text style={{ fontWeight: '600', fontSize: 13, marginTop: 2, color: appColors.primary }}>
                            {productData.name}
                          </Text>
                        </View>
                        <ReactBarcode value={item} options={{ height: 50 }} />
                      </View>
                    </Card.Content>
                  </Card>
                )}
                data={productData.barcode}
              />
            </View>
            <CustomDialog />
          </div>
        }
      />
    </>
  );
};

export default AddProductByScannerScreen;

import { useRef, useEffect, useCallback } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { Card } from 'react-native-paper';
import { Svg, Path } from 'react-native-svg';
import { appColors } from '../global/constant/colors';
import { ReactBarcode } from 'react-jsbarcode';
import { ProductItemDataType } from '../global/constant/types';
import { WebView } from 'react-native-webview';
import { CustomButton, CustomModal, CustomInput } from '../components';
import { useState } from 'react';
import useProduct from '../hooks/useProduct';
import useOrg from '../hooks/useOrg';

interface IProps {
  data: any;
  productNameGroupParamsData: any;
  showDeleteProductModal: (id: number) => void;
  navigation: any;
}

const CustomAdminProductCard = ({ data, productNameGroupParamsData, showDeleteProductModal, navigation }: IProps) => {
  const { getOrgData, getShopData, shopData, org } = useOrg();
  const { updateProductStock, printLabel, updateProduct } = useProduct();
  const [singItemData, setSingItemData] = useState({
    stock: '',
    product_id: '',
    stock_value: 0,
  });

  useEffect(() => {
    getOrgData();
    getShopData();
  }, []);
  const [editVisible, setEditVisible] = useState(false);

  const showModalEdit = (val: any) => {
    setSingItemData({ stock: '', product_id: val?.product_id, stock_value: val.stock_value == 'kilogram' ? 1 : 0 });
    setEditVisible(true);
    console.log('val=>:', val);
  };

  const hideModalEdit = () => {
    setEditVisible(false);
  };

  const [printVisible, setPrintVisible] = useState(false);

  const showModalPrint = (val: any) => {
    setPrintVisible(true);
    console.log('val=>:', val);
  };

  const hideModalPrint = () => {
    setPrintVisible(false);
  };

  const [productUpdateData, setProductUpdateData] = useState({
    product_id: '',
    cost_per_unit: '',
    expiry_date: '',
    wholesale_price: '',
    discount_unit: '',
    discount_price: '',
  });

  const [updateProductModalVisible, setUpdateProductModalVisible] = useState(false);

  const showModalUpdateProductModal = (val: any) => {
    setProductUpdateData({
      cost_per_unit: data.cost_per_unit,
      expiry_date: data.expiry_dat,
      wholesale_price: data.wholesale_price,
      discount_unit: data.discount_unit,
      discount_price: data.discount_price,
      product_id: val?.product_id,
    });
    setUpdateProductModalVisible(true);
    console.log('val=>:', val);
  };

  const hideModalUpdateProductModal = () => {
    setUpdateProductModalVisible(false);
  };

  const onClear = () => {
    setSingItemData({ stock: '', product_id: '', stock_value: 0 });
    // setProductUpdateData({ cost_per_unit: '', product_id: '' });
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: '#ffffff',
          height: 400,
          width: '100%',
          marginTop: 10,
          marginBottom: 5,
          marginRight: 5,
          marginLeft: 5,
        }}
        mode="elevated"
      >
        <Card.Content>
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ marginLeft: 10 }}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={{ fontWeight: '700', fontSize: 16, marginTop: 10, color: appColors.primary, width: 230 }}
                >
                  {data?.product_name.toUpperCase()}
                </Text>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Stock: {data?.stock_remaining} {data?.stock_value}
                </Text>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Price: {org?.country?.currency_symbol} {data?.price}
                </Text>
                {data?.cost_per_unit != null && (
                  <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                    Cost Per Unit: {org?.country?.currency_symbol} {data?.cost_per_unit}
                  </Text>
                )}
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Wholesale Price: {org?.country?.currency_symbol} {data?.wholesale_price}
                </Text>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Discount Price: {org?.country?.currency_symbol} {data.discount_price}
                </Text>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Discount Unit: {data.discount_unit}
                </Text>
                <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                  Expiry Date: {data.expiry_date}
                </Text>
              </View>
              <View>
                {data?.is_single == 0 && (
                  <View style={{ marginRight: 10, marginLeft: 10 }}>
                    <CustomButton
                      fontSize={14}
                      padding={5}
                      width={200}
                      bntType="primary"
                      mode="outlined"
                      text="PRINT BARCODE"
                      onPress={showModalPrint}
                    />
                  </View>
                )}
                <ReactBarcode options={{ height: 120 }} value={data?.barcode} />
              </View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 20, marginTop: 10 }}>
                <View style={{ marginRight: 10, marginLeft: 10 }}>
                  <CustomButton
                    fontSize={14}
                    padding={5}
                    width={200}
                    bntType="primary"
                    mode="outlined"
                    text="Edit Stock"
                    onPress={() => showModalEdit(data)}
                  />
                </View>
                <View style={{ marginRight: 10, marginLeft: 10 }}>
                  <CustomButton
                    fontSize={14}
                    padding={5}
                    width={200}
                    bntType="primary"
                    mode="outlined"
                    text="Edit Details"
                    onPress={() => showModalUpdateProductModal(data)}
                  />
                </View>

                <View style={{ marginRight: 10, marginLeft: 10 }}>
                  <CustomButton
                    fontSize={14}
                    padding={5}
                    width={200}
                    bntType="primary"
                    mode="outlined"
                    text="Delete"
                    onPress={() => showDeleteProductModal(data.product_id)}
                  />
                </View>
              </View>
            </View>
          </View>
        </Card.Content>
      </Card>
      <CustomModal
        content={
          <>
            <div style={{ overflow: 'auto', height: '200px' }}>
              <View style={{ width: '100%', marginBottom: 20 }}>
                <CustomInput
                  value={singItemData.stock}
                  placeholder="Product Stock"
                  onChangeText={(e) => setSingItemData({ ...singItemData, stock: e })}
                  inputMode="text"
                />
              </View>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomButton
                  width={'40%'}
                  bntType="primary"
                  mode="contained"
                  text={'Add'}
                  onPress={() =>
                    updateProductStock(singItemData, data?.product_name_group_id, 'add', hideModalEdit, onClear)
                  }
                />
                <CustomButton
                  width={'40%'}
                  bntType="secondary"
                  mode="contained"
                  text={'Reduce'}
                  onPress={() =>
                    updateProductStock(singItemData, data?.product_name_group_id, 'reduce', hideModalEdit, onClear)
                  }
                />
              </div>
            </div>
          </>
        }
        marginLeft={450}
        marginRight={450}
        hideModal={hideModalEdit}
        visible={editVisible}
      />

      <CustomModal
        content={
          <>
            <View style={{ marginBottom: 20 }}>
              <View style={{ marginBottom: 20 }}>
                <CustomInput
                  value={productUpdateData.cost_per_unit}
                  placeholder="Product Cost Per Unit"
                  onChangeText={(e) => setProductUpdateData({ ...productUpdateData, cost_per_unit: e })}
                  inputMode="text"
                  label="Product Cost Per Unit"
                />
              </View>
              <View style={{ marginBottom: 20 }}>
                <CustomInput
                  value={productUpdateData.wholesale_price}
                  placeholder="Wholesale Price"
                  onChangeText={(e) => setProductUpdateData({ ...productUpdateData, wholesale_price: e })}
                  inputMode="text"
                  label="Wholesale Price"
                />
              </View>
              <View style={{ marginBottom: 20 }}>
                <CustomInput
                  value={productUpdateData.discount_price}
                  placeholder="Discount Price"
                  onChangeText={(e) => setProductUpdateData({ ...productUpdateData, discount_price: e })}
                  inputMode="text"
                  label="Discount Price"
                />
              </View>
              <View style={{ marginBottom: 20 }}>
                <CustomInput
                  value={productUpdateData.discount_unit}
                  placeholder="Discount Unit"
                  onChangeText={(e) => setProductUpdateData({ ...productUpdateData, discount_unit: e })}
                  inputMode="text"
                  label="Discount Unit"
                />
              </View>
              <View style={{ marginBottom: 20 }}>
                <Text
                  style={{
                    color: '#271E00',
                    fontSize: 17,
                    fontWeight: '400',
                    height: 24,
                    lineHeight: 24,
                    marginBottom: 5,
                  }}
                >
                  Expiry Date
                </Text>
                <input
                  onChange={(e: any) => setProductUpdateData({ ...productUpdateData, expiry_date: e.target.value })}
                  type="date"
                  style={{
                    height: 25,
                    borderWidth: 0.3,
                    padding: 13,
                    borderColor: '#271E00',
                    borderRadius: 10,
                    color: '#3D3D3D',
                    fontSize: 14,
                    fontWeight: '500',
                    backgroundColor: '#efeded',
                  }}
                />
              </View>
            </View>
            <View>
              <CustomButton
                width={'100%'}
                bntType="primary"
                mode="contained"
                text={'Save'}
                onPress={() =>
                  updateProduct(productUpdateData, productNameGroupParamsData.id, hideModalUpdateProductModal, onClear)
                }
              />
            </View>
          </>
        }
        marginLeft={450}
        marginRight={450}
        hideModal={hideModalUpdateProductModal}
        visible={updateProductModalVisible}
      />

      <CustomModal
        content={
          <>
            <View style={{ marginBottom: 20 }}>
              <CustomButton
                width={'100%'}
                bntType="primary"
                mode="outlined"
                text={'Print Small'}
                onPress={() => {
                  hideModalPrint(),
                    printLabel(
                      data?.product_name?.toUpperCase(),
                      data?.price,
                      data?.barcode,
                      org?.country?.currency_symbol,
                      shopData?.shop_name,
                      `${shopData?.address} ${shopData?.city}`,
                      'small',
                    );
                }}
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <CustomButton
                width={'100%'}
                bntType="primary"
                mode="outlined"
                text={'Print Medium'}
                onPress={() => {
                  hideModalPrint(),
                    printLabel(
                      data?.product_name?.toUpperCase(),
                      data?.price,
                      data?.barcode,
                      org?.country?.currency_symbol,
                      shopData?.shop_name,
                      `${shopData?.address} ${shopData?.city}`,
                      'medium',
                    );
                }}
              />
            </View>

            <View>
              <CustomButton
                width={'100%'}
                bntType="primary"
                mode="outlined"
                text={'Print Large'}
                onPress={() => {
                  hideModalPrint(),
                    printLabel(
                      data?.product_name?.toUpperCase(),
                      data?.price,
                      data?.barcode,
                      org?.country?.currency_symbol,
                      shopData?.shop_name,
                      `${shopData?.address} ${shopData?.city}`,
                      'large',
                    );
                }}
              />
            </View>
          </>
        }
        marginLeft={550}
        marginRight={550}
        hideModal={hideModalPrint}
        visible={printVisible}
      />
    </>
  );
};

export default CustomAdminProductCard;

import { GET_TRANSACTION_LIST, GET_RECEIPT } from '../global/constant/apiRoutes';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTransactionData,
  setReceiptData,
  setTransactionTotalSumData,
  setTransactionTotalProfitData,
} from '../global/appState/slice/customTransactionSlice';
import { getAsyncStorage } from '../global/utils/asyncFun';
import { RootState } from '../global/appState/store';
import moment from 'moment';

const useTransaction = () => {
  const dispatch = useDispatch();
  const { apiCall } = useAxios();
  const { onSetPreloadState } = useDialogState();
  const { transactionData, receiptData, totalSum, estimated_profit }: any = useSelector((state: RootState) => state.transaction);

  const getTransactionList = async (per_page?: number, page?: number) => {
    onSetPreloadState(true);
    let shopId = await getAsyncStorage('shop');
    try {
      const res = await apiCall(
        'GET',
        page == undefined
          ? `${GET_TRANSACTION_LIST}?shop_id=${shopId.id}`
          : `${GET_TRANSACTION_LIST}?shop_id=${shopId.id}&page=${page}`,
      );
      console.log('TRANSACTION =>', res.data.orders);
      dispatch(setTransactionData(res.data.orders));
      dispatch(setTransactionTotalSumData(res.data.total_sum));
      dispatch(setTransactionTotalProfitData(res.data.estimated_profit));
      onSetPreloadState(false);
    } catch (error) {
      console.log('get transaction error', error);
      onSetPreloadState(false);
    }
  };

  const getTransactionListByDate = async (_data?: { date_from: string; date_to: string }, page?: number) => {
    onSetPreloadState(true);
    let shopId = await getAsyncStorage('shop');
    try {
      const res = await apiCall(
        'GET',
        page == undefined
          ? `${GET_TRANSACTION_LIST}?shop_id=${shopId.id}&date_from=${moment(_data.date_from).format('YYYY-MM-DD')}&date_to=${moment(_data.date_to).format('YYYY-MM-DD')}`
          : `${GET_TRANSACTION_LIST}?shop_id=${shopId.id}&date_from=${moment(_data.date_from).format('YYYY-MM-DD')}&date_to=${moment(_data.date_to).format('YYYY-MM-DD')}&page=${page}`,
      );
      console.log('TRANSACTION =>', res.data.orders);
      dispatch(setTransactionData(res.data.orders));
      dispatch(setTransactionTotalSumData(res.data.total_sum));
      onSetPreloadState(false);
    } catch (error) {
      console.log('get transaction error', error);
      onSetPreloadState(false);
    }
  };

  const getReceipt = async (order_id: number) => {
    onSetPreloadState(true);
    try {
      const res = await apiCall('GET', `${GET_RECEIPT}?order_id=${order_id}`);
      console.log('receipt =>', res.data);
      dispatch(setReceiptData(res.data.reciept));
      onSetPreloadState(false);
    } catch (error) {
      console.log('get receipt error', error);
      onSetPreloadState(false);
    }
  };

  const onClearReceiptData = () => {
    dispatch(setReceiptData({}));
  };

  return {
    getTransactionList,
    getTransactionListByDate,
    getReceipt,
    onClearReceiptData,
    transactionData,
    receiptData,
    totalSum,
    estimated_profit
  };
};

export default useTransaction;

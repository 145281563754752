import { createSlice } from '@reduxjs/toolkit';
import { InventoryScreen } from 'screens/admin';

// Define the reducer logic for 'twoSlice'
const customProductSlice = createSlice({
  name: 'customProductState',
  initialState: {
    productNameGroupData: [],
    productNameItemData: [],
    allShopProductData: [],
    inventoryData: [],
    stockRecordData: [],
  },
  reducers: {
    setProductNameGroupData(state, action) {
      state.productNameGroupData = action.payload;
    },
    setProductItemData(state, action) {
      state.productNameItemData = action.payload;
    },
    setAllShopProductData(state, action) {
      state.allShopProductData = action.payload;
    },
    setInventoryData(state, action) {
      state.inventoryData = action.payload;
    },
    setStockRecordData(state, action) {
      state.stockRecordData = action.payload;
    },


   
  },
});

export const { setProductNameGroupData, setProductItemData, setAllShopProductData, setInventoryData, setStockRecordData } =
  customProductSlice.actions;

export default customProductSlice;

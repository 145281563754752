import { useState, FC, useEffect } from 'react';
import { View, TouchableOpacity, Text, TextInput, Image, FlatList } from 'react-native';
import { appColors } from '../../global/constant/colors';
import { CustomButton, CustomAdminProductCard, CustomModal, CustomInput } from '../../components';
import { Svg, Path } from 'react-native-svg';
import { ProductScreenProps } from '../../navigation/appNavigation';
import AdminLayout from '../../components/layout/AdminLayout';
import useProduct from '../../hooks/useProduct';
import { ProductItemDataType } from '../../global/constant/types';
import { ReactBarcode } from 'react-jsbarcode';
import { Card, Divider, Button, Dialog, Portal } from 'react-native-paper';
import useOrg from '../../hooks/useOrg';
import BarcodeReader from 'react-barcode-reader';
import { toast } from 'react-toastify';

const ProductScreen: FC<ProductScreenProps> = ({ navigation, route }: any) => {
  const [visible, setVisible] = useState(false);
  const { getOrgData, org } = useOrg();
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const {
    getProductItem,
    updateProductStock,
    updateProductBarcode,
    productNameItemData,
    editProductNameGroup,
    deleteProduct,
    updateProduct,
  } = useProduct();
  const { productNameGroupParamsData }: any = route.params;

  const [productNameGroupInfo, setProductNameGroupInfo] = useState({
    product_name_group: '',
    product_name_group_id: 0,
    product_category_id: 0,
    price: '',
  });

  const [editGroupNameModal, setEditGroupNameModal] = useState(false);

  const onCloseEditGroupNameModal = () => {
    setEditGroupNameModal(false);
  };

  const onUpdateProductGroupName = () => {
    editProductNameGroup(productNameGroupInfo, onCloseEditGroupNameModal);
  };

  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [productId, setProductId] = useState(0);

  const hideDeleteProductModal = () => setDeleteProductModal(false);

  const showDeleteProductModal = (id: number) => {
    setProductId(id);
    setDeleteProductModal(true);
  };

  console.log('productNameGroupParamsData', productNameGroupParamsData);

  const onOpenEditGroupNameModal = () => {
    setProductNameGroupInfo({
      product_name_group: productNameGroupParamsData.product_name_group,
      product_name_group_id: productNameGroupParamsData.id,
      product_category_id: productNameGroupParamsData.product_category_id,
      price: productNameGroupParamsData.price,
    });
    setEditGroupNameModal(true);
  };

  const onCloseSetVisible = () => {
    setVisible(false);
  };

  useEffect(() => {
    getProductItem(productNameGroupParamsData.id);
    getOrgData();
  }, []);

  const onMoveToAddProductManually = () => {
    onCloseSetVisible();
    navigation.replace('addProductManuallyScreen', {
      productNameGroupParamsData: productNameGroupParamsData,
    });
  };
  const onMoveToAddProductByScanner = () => {
    onCloseSetVisible();
    navigation.replace('addProductByScannerScreen', {
      productNameGroupParamsData: productNameGroupParamsData,
    });
  };

  const [singItemData, setSingItemData] = useState({
    stock: '',
    product_id: '',
    stock_value: 0,
  });

  const [barcodeToUpdate, setBarcodeToUpdate] = useState({
    barcode: [],
    product_id: '',
  });

  const [editVisible, setEditVisible] = useState(false);
  const [barcodeVisible, setBarcodeVisible] = useState(false);

  const showModalEdit = (val: any) => {
    setSingItemData({ stock: '', product_id: val?.product_id, stock_value: val.stock_value == 'kilogram' ? 1 : 0 });
    setEditVisible(true);
    console.log('val=>:', val);
  };

  const hideModalEdit = () => {
    setEditVisible(false);
  };

  const [productUpdateData, setProductUpdateData] = useState({
    product_id: '',
    cost_per_unit: '',
    expiry_date: '',
    wholesale_price: '',
    discount_unit: '',
    discount_price: '',
  });

  const [updateProductModalVisible, setUpdateProductModalVisible] = useState(false);

  const showModalUpdateProductModal = (val: any) => {
    setProductUpdateData({
      cost_per_unit: productNameItemData[0]?.cost_per_unit,
      expiry_date: productNameItemData[0]?.expiry_date,
      wholesale_price: productNameItemData[0]?.wholesale_price,
      discount_unit: productNameItemData[0]?.discount_unit,
      discount_price: productNameItemData[0]?.discount_price,
      product_id: val?.product_id,
    });
    setUpdateProductModalVisible(true);
    console.log('val=>:', val);
  };

  const hideModalUpdateProductModal = () => {
    setUpdateProductModalVisible(false);
  };

  const onClear = () => {
    setSingItemData({ stock: '', product_id: '', stock_value: 0 });
    setBarcodeToUpdate({ barcode: [], product_id: '' });
    // setProductUpdateData({ cost_per_unit: '', product_id: '' });
  };

  const handleScan = (result: any) => {
    if (result) {
      if (barcodeToUpdate.barcode.find((data: any) => data == result)) {
        toast.error(`${result} already listed`, {
          position: 'top-right',
        });
      } else {
        setBarcodeToUpdate((prevData) => ({
          product_id: productNameItemData[0]?.product_id,
          barcode: [...prevData.barcode, result],
        }));
        console.log('scan result', result);
      }
    }
  };

  const showModalBarcode = (val: any) => {
    setBarcodeVisible(true);
  };

  const hideModalBarcode = () => {
    setBarcodeVisible(false);
  };

  const onsubmitBarcode = () => {
    updateProductBarcode(barcodeToUpdate, productNameGroupParamsData.id, hideModalEdit, onClear);
    hideModalBarcode();
  };

  const removeBarcode = (val: any) => {
    let data = barcodeToUpdate.barcode.filter((dataItem: any) => dataItem !== val);
    setBarcodeToUpdate({ ...barcodeToUpdate, barcode: data });
  };

  return (
    <>
      <AdminLayout
        content={
          <>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <View style={{ flexDirection: 'row' }}>
                    <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                      <Path
                        d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                        fill="#1E1E1E"
                      />
                    </Svg>
                    <Text
                      style={{
                        fontWeight: '700',
                        color: 'rgba(30, 30, 30, 1)',
                        marginLeft: 10,
                      }}
                    >
                      PRODUCTS
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={{ marginLeft: 'auto' }}>
                <View style={{ flexDirection: 'row' }}>
                  {productNameItemData.length > 0 ? (
                    <></>
                  ) : (
                    <CustomButton
                      fontSize={14}
                      padding={5}
                      width={200}
                      bntType="primary"
                      mode="contained"
                      text="ADD PRODUCT"
                      onPress={showModal}
                    />
                  )}
                </View>
              </View>
            </View>
            {productNameItemData.length > 0 && (
              <View style={{ marginTop: 40 }}>
                {productNameItemData[0]?.is_single == 0 ? (
                  <CustomAdminProductCard
                    data={productNameItemData[0]}
                    productNameGroupParamsData={productNameGroupParamsData}
                    showDeleteProductModal={showDeleteProductModal}
                    navigation={navigation}
                  />
                ) : (
                  <FlatList
                    contentContainerStyle={{
                      justifyContent: 'space-between',
                      margin: 10,
                    }}
                    keyExtractor={(item: any) => item.id}
                    numColumns={4}
                    ListHeaderComponent={
                      <View
                        style={{
                          marginTop: 10,
                          backgroundColor: '#ffffff',
                          height: 400,
                          padding: 20,
                          borderRadius: 15,
                          paddingTop: 30,
                        }}
                      >
                        <View>
                          <View style={{ marginRight: 100 }}>
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{
                                fontWeight: '700',
                                fontSize: 16,
                                marginTop: 10,
                                color: appColors.primary,
                                width: 230,
                              }}
                            >
                              {productNameItemData[0]?.product_name.toUpperCase()}
                            </Text>
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Stock: {productNameItemData[0]?.stock_remaining} {productNameItemData[0]?.stock_value}
                            </Text>
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Price: {org?.country?.currency_symbol} {productNameItemData[0]?.price}
                            </Text>
                            {productNameItemData[0]?.cost_per_unit != null && (
                              <Text
                                style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}
                              >
                                Cost Per Unit: {org?.country?.currency_symbol} {productNameItemData[0]?.cost_per_unit}
                              </Text>
                            )}
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Wholesale Price: {org?.country?.currency_symbol} {productNameItemData[0]?.wholesale_price}
                            </Text>
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Discount Price: {org?.country?.currency_symbol} {productNameItemData[0]?.discount_price}
                            </Text>
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Discount Unit: {productNameItemData[0]?.discount_unit}
                            </Text>
                            <Text style={{ fontWeight: '600', fontSize: 16, marginTop: 10, color: appColors.primary }}>
                              Expiry Date: {productNameItemData[0]?.expiry_date}
                            </Text>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ marginRight: 10, marginTop: 10 }}>
                            <CustomButton
                              fontSize={14}
                              padding={5}
                              width={200}
                              bntType="primary"
                              mode="outlined"
                              text="Edit Stock"
                              onPress={() => showModalEdit(productNameItemData[0])}
                            />
                          </View>

                          <View style={{ marginRight: 10, marginTop: 10 }}>
                            <CustomButton
                              fontSize={14}
                              padding={5}
                              width={200}
                              bntType="primary"
                              mode="outlined"
                              text="Edit Details"
                              onPress={() => showModalUpdateProductModal(productNameItemData[0])}
                            />
                          </View>

                          <View style={{ marginLeft: 10, marginTop: 10 }}>
                            <CustomButton
                              fontSize={14}
                              padding={5}
                              width={200}
                              bntType="primary"
                              mode="outlined"
                              text="Add Barcode"
                              onPress={showModalBarcode}
                            />
                          </View>
                          <View style={{ marginLeft: 10, marginTop: 10 }}>
                            <CustomButton
                              fontSize={14}
                              padding={5}
                              width={200}
                              bntType="primary"
                              mode="outlined"
                              text="Delete"
                              onPress={() => showDeleteProductModal(productNameItemData[0].product_id)}
                            />
                          </View>
                        </View>
                      </View>
                    }
                    renderItem={({ item }) => (
                      <Card
                        style={{
                          backgroundColor: '#ffffff',
                          height: 150,
                          width: 300,
                          marginRight: 20,
                          marginBottom: 5,
                          marginTop: 20,
                          marginLeft: 5,
                        }}
                        mode="elevated"
                      >
                        <Card.Content>
                          <View style={{ alignItems: 'center' }}>
                            <ReactBarcode value={item.barcode} options={{ height: 50, width: 1.3 }} />
                          </View>
                        </Card.Content>
                      </Card>
                    )}
                    data={productNameItemData}
                  />
                )}
              </View>
            )}

            <CustomModal
              content={
                <>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TouchableOpacity onPress={onMoveToAddProductByScanner}>
                      <Image
                        style={{ height: 150, width: 150, marginRight: 30 }}
                        source={require('../../assets/scantoadd.png')}
                      />
                    </TouchableOpacity>
                    {productNameItemData.length > 0 && productNameItemData[0].is_single == 1 ? (
                      <></>
                    ) : (
                      <TouchableOpacity onPress={onMoveToAddProductManually}>
                        <Image
                          style={{ height: 150, width: 150, marginLeft: 30 }}
                          source={require('../../assets/manuallytoadd.png')}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </>
              }
              marginLeft={450}
              marginRight={450}
              hideModal={hideModal}
              visible={visible}
            />
          </>
        }
      />
      <CustomModal
        content={
          <>
            <View style={{ width: '100%', marginBottom: 20 }}>
              <CustomInput
                value={singItemData.stock}
                placeholder="Product Stock"
                onChangeText={(e) => setSingItemData({ ...singItemData, stock: e })}
                inputMode="text"
              />
            </View>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CustomButton
                width={'40%'}
                bntType="primary"
                mode="contained"
                text={'Add'}
                onPress={() =>
                  updateProductStock(singItemData, productNameGroupParamsData.id, 'add', hideModalEdit, onClear)
                }
              />
              <CustomButton
                width={'40%'}
                bntType="secondary"
                mode="contained"
                text={'Reduce'}
                onPress={() =>
                  updateProductStock(singItemData, productNameGroupParamsData.id, 'reduce', hideModalEdit, onClear)
                }
              />
            </div>
          </>
        }
        marginLeft={450}
        marginRight={450}
        hideModal={hideModalEdit}
        visible={editVisible}
      />

      <CustomModal
        content={
          <>
            <BarcodeReader onScan={handleScan} />
            <div style={{ overflow: 'auto', height: '500px' }}>
              <View style={{ width: '100%', marginBottom: 20 }}>
                <FlatList
                  contentContainerStyle={{
                    justifyContent: 'space-between',
                    margin: 10,
                  }}
                  keyExtractor={(item: any) => item.id}
                  numColumns={4}
                  renderItem={({ item }) => (
                    <View>
                      <Card
                        style={{
                          backgroundColor: '#ffffff',
                          height: 120,
                          width: 290,
                          marginRight: 20,
                          marginBottom: 5,
                          marginTop: 20,
                          marginLeft: 5,
                        }}
                        mode="elevated"
                      >
                        <Card.Content>
                          <View style={{ alignItems: 'center' }}>
                            <ReactBarcode value={item} options={{ height: 40, width: 1.3 }} />
                          </View>
                        </Card.Content>
                      </Card>
                      <TouchableOpacity
                        onPress={() => removeBarcode(item)}
                        style={{
                          backgroundColor: 'rgba(183, 35, 0, 1)',
                          padding: 15,
                          borderRadius: 10,
                          width: 290,
                          marginRight: 10,
                        }}
                      >
                        <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700' }}>Remove</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  data={barcodeToUpdate.barcode}
                />
              </View>

              <div>
                {barcodeToUpdate.barcode.length > 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 100 }}>
                    <Divider />
                    <CustomButton
                      padding={10}
                      width={'20%'}
                      bntType="primary"
                      mode="contained"
                      text={'Update'}
                      onPress={() => onsubmitBarcode()}
                    />
                  </div>
                ) : (
                  <p style={{ fontWeight: 'bold', color: 'GrayText', textAlign: 'center' }}>SCAN A PRODUCT BARCODE</p>
                )}
              </div>
            </div>
          </>
        }
        marginLeft={50}
        marginRight={50}
        hideModal={hideModalBarcode}
        visible={barcodeVisible}
      />

      <CustomModal
        content={
          <>
            <div style={{ overflow: 'auto', height: '300px' }}>
              <View style={{ width: '100%' }}>
                <CustomInput
                  value={productNameGroupInfo.product_name_group}
                  placeholder="Product Group Name"
                  label="Product Group Name"
                  onChangeText={(e) => setProductNameGroupInfo({ ...productNameGroupInfo, product_name_group: e })}
                  inputMode="text"
                />
              </View>
              <View style={{ width: '100%', marginTop: 30 }}>
                <CustomInput
                  value={productNameGroupInfo.price}
                  placeholder="Product Group Price"
                  label="Product Group Price"
                  onChangeText={(e) => setProductNameGroupInfo({ ...productNameGroupInfo, price: e })}
                  inputMode="numeric"
                />
              </View>
              <View style={{ marginTop: 30 }}>
                <CustomButton
                  fontSize={14}
                  padding={5}
                  width={'100%'}
                  bntType="primary"
                  mode="contained"
                  text="UPDATE"
                  // loading={authLoading}
                  onPress={onUpdateProductGroupName}
                />
              </View>
            </div>
          </>
        }
        marginLeft={500}
        marginRight={500}
        hideModal={onCloseEditGroupNameModal}
        visible={editGroupNameModal}
      />

      <CustomModal
        content={
          <>
            <View style={{ width: '100%', marginBottom: 20 }}>
              <CustomInput
                value={productUpdateData.cost_per_unit}
                placeholder="Product Cost Per Unit"
                onChangeText={(e) => setProductUpdateData({ ...productUpdateData, cost_per_unit: e })}
                inputMode="text"
                label='Product Cost Per Unit'
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <CustomInput
                value={productUpdateData.wholesale_price}
                placeholder="Wholesale Price"
                onChangeText={(e) => setProductUpdateData({ ...productUpdateData, wholesale_price: e })}
                inputMode="text"
                label="Wholesale Price"
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <CustomInput
                value={productUpdateData.discount_price}
                placeholder="Discount Price"
                onChangeText={(e) => setProductUpdateData({ ...productUpdateData, discount_price: e })}
                inputMode="text"
                label="Discount Price"
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <CustomInput
                value={productUpdateData.discount_unit}
                placeholder="Discount Unit"
                onChangeText={(e) => setProductUpdateData({ ...productUpdateData, discount_unit: e })}
                inputMode="text"
                label="Discount Unit"
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text
                style={{
                  color: '#271E00',
                  fontSize: 17,
                  fontWeight: '400',
                  height: 24,
                  lineHeight: 24,
                  marginBottom: 5,
                }}
              >
                Expiry Date
              </Text>
              <input
                onChange={(e: any) => setProductUpdateData({ ...productUpdateData, expiry_date: e.target.value })}
                type="date"
                style={{
                  height: 25,
                  borderWidth: 0.3,
                  padding: 13,
                  borderColor: '#271E00',
                  borderRadius: 10,
                  color: '#3D3D3D',
                  fontSize: 14,
                  fontWeight: '500',
                  backgroundColor: '#efeded',
                }}
              />
            </View>
            <View>
              <CustomButton
                width={'100%'}
                bntType="primary"
                mode="contained"
                text={'Save'}
                onPress={() =>
                  updateProduct(productUpdateData, productNameGroupParamsData.id, hideModalUpdateProductModal, onClear)
                }
              />
            </View>
          </>
        }
        marginLeft={450}
        marginRight={450}
        hideModal={hideModalUpdateProductModal}
        visible={updateProductModalVisible}
      />

      <Portal>
        <Dialog
          style={{ marginRight: 500, marginLeft: 500 }}
          visible={deleteProductModal}
          onDismiss={hideDeleteProductModal}
        >
          <Dialog.Title style={{ color: 'red' }}>Delete Product</Dialog.Title>
          <Dialog.Content>Do you want to delete this product?</Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideDeleteProductModal}>Cancel</Button>
            <Button
              style={{ backgroundColor: 'red', width: 80 }}
              onPress={() =>
                deleteProduct(productId, productNameGroupParamsData.product_category_id, hideDeleteProductModal)
              }
            >
              <Text style={{ color: '#ffffff' }}>Yes</Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
};

export default ProductScreen;

import { View, Image, Text } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import React from 'react';
import useDialogState from '../hooks/useDialogState';

const CustomSpinner = () => {
  const { preLoaderState } = useDialogState();
  console.log('lofing==>', preLoaderState);
  return (
    <div style={{ height: '100vh' }}>
      <Portal>
        <Modal style={{ borderWidth: 0 }} visible={preLoaderState}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Image
              style={{ height: 200, width: 200 }}
              source={require('../assets/spinner.gif')}
            />
          </div>
        </Modal>
      </Portal>
    </div>
  );
};

export default CustomSpinner;

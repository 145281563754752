import { View, Text, TouchableOpacity, TextInput, ScrollView, FlatList } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { ProductNameScreenProps } from '../../navigation/appNavigation';
import AdminLayout from '../../components/layout/AdminLayout';
import { CustomButton, CustomDialog, CustomInput, CustomModal } from '../../components';
import { Svg, Path } from 'react-native-svg';
import useProduct from '../../hooks/useProduct';
import { appColors } from '../../global/constant/colors';
import useOrg from '../../hooks/useOrg';
import { Menu, Divider, Dialog, Portal, Button } from 'react-native-paper';

const ProductNameScreen: FC<ProductNameScreenProps> = ({ navigation, route }: any) => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [productNameGroupInfo, setProductNameGroupInfo] = useState({
    product_name_group: '',
    price: '',
  });
  const {
    getAllProductNameGroup,
    createProductNameGroup,
    editProductNameGroup,
    archiveProductGroupName,
    deleteProductGroupName,
    productNameGroupData,
  } = useProduct();
  const { categoryParamsData }: any = route?.params;

  const onClearFun = () => {
    setProductNameGroupInfo({
      product_name_group: '',
      price: '',
    });
  };

  const onSubmit = () => {
    createProductNameGroup({ ...productNameGroupInfo, product_category_id: categoryParamsData.id }, onClearFun);
  };

  const { getOrgData, org } = useOrg();

  useEffect(() => {
    getAllProductNameGroup(categoryParamsData?.id);
    getOrgData();
  }, []);

  useEffect(() => {
    getAllProductNameGroup(categoryParamsData?.id, searchQuery);
    getOrgData();
  }, [searchQuery]);

  const [visible, setVisible] = React.useState(false);
  const [productNameGroupParamsData, setProductNameGroupParamsData] = React.useState<any>({});

  const openMenu = (data: any) => {
    setVisible(true);
    setProductNameGroupParamsData(data);
  };

  const closeMenu = () => {
    setVisible(false);
    setProductNameGroupParamsData({});
  };

  const [productNameGroupInfoEdit, setProductNameGroupInfoEdit] = useState({
    product_name_group: '',
    product_name_group_id: 0,
    product_category_id: 0,
    price: '',
  });

  const [editGroupNameModal, setEditGroupNameModal] = useState(false);

  const onCloseEditGroupNameModal = () => {
    setEditGroupNameModal(false);
  };

  const onOpenEditGroupNameModal = () => {
    setProductNameGroupInfoEdit({
      product_name_group: productNameGroupParamsData.product_name_group,
      product_name_group_id: productNameGroupParamsData.id,
      product_category_id: productNameGroupParamsData.product_category_id,
      price: productNameGroupParamsData.price,
    });
    setEditGroupNameModal(true);
    setVisible(false);
  };

  const onUpdateProductGroupName = () => {
    editProductNameGroup(productNameGroupInfoEdit, onCloseEditGroupNameModal);
  };

  const [archiveProductGroupNameModal, setArchiveProductGroupNameModal] = useState(false);
  const hideArchiveProductGroupNameModal = () => setArchiveProductGroupNameModal(false);
  const showArchiveProductGroupNameModal = () => {
    setArchiveProductGroupNameModal(true);
    setVisible(false);
  };

  const [deleteProductGroupNameModal, setDeleteProductGroupNameModal] = useState(false);
  const hideDeleteProductGroupNameModal = () => setDeleteProductGroupNameModal(false);

  const showDeleteProductGroupNameModal = () => {
    setDeleteProductGroupNameModal(true);
    setVisible(false);
  };

  return (
    <>
      <AdminLayout
        content={
          <>
            <View style={{ flex: 1, position: 'absolute', zIndex: 100 }}>
              <View style={{ flexDirection: 'row' }}>
                <View>
                  <TouchableOpacity onPress={() => (show ? setShow(false) : navigation.navigate('categoryScreen'))}>
                    <View style={{ flexDirection: 'row' }}>
                      <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                        <Path
                          d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                          fill="#1E1E1E"
                        />
                      </Svg>
                      <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>
                        {show
                          ? `CREATE PRODUCT NAME ${categoryParamsData?.name?.toUpperCase()}`
                          : `${categoryParamsData?.name?.toUpperCase()} CATEGORY`}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                {!show && (
                  <View style={{ marginLeft: '55%' }}>
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ marginRight: 10 }}>
                        <TextInput
                          textAlign="center"
                          textAlignVertical="center"
                          keyboardAppearance="default"
                          placeholder="Search"
                          style={{
                            height: 50,
                            padding: 13,
                            borderColor: 'rgba(30, 30, 30, 0.5)',
                            borderWidth: 1,
                            borderRadius: 10,
                            width: 400,
                            backgroundColor: '#efeded',
                          }}
                          value={searchQuery}
                          onChangeText={setSearchQuery}
                        />
                      </View>
                      <CustomButton
                        fontSize={14}
                        padding={5}
                        width={250}
                        bntType="primary"
                        mode="contained"
                        text="ADD PRODUCT NAME"
                        onPress={() => setShow(true)}
                      />
                    </View>
                  </View>
                )}
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginTop: 40,
              }}
            >
              {show ? (
                <ScrollView style={{ marginRight: 200, marginLeft: 200 }}>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View
                      style={{
                        width: '45%',
                        height: '100%',
                        marginBottom: 50,
                        marginTop: 10,
                        backgroundColor: '#ffffff',
                        padding: 30,
                        borderRadius: 20,
                      }}
                    >
                      <CustomInput
                        value={productNameGroupInfo.product_name_group}
                        placeholder="Product Group Name"
                        onChangeText={(e) =>
                          setProductNameGroupInfo({ ...productNameGroupInfo, product_name_group: e })
                        }
                        inputMode="text"
                      />
                      <CustomInput
                        value={productNameGroupInfo.price}
                        placeholder="Product Group Price"
                        onChangeText={(e) => setProductNameGroupInfo({ ...productNameGroupInfo, price: e })}
                        inputMode="numeric"
                      />
                      <View style={{ marginTop: 30 }}>
                        <CustomButton
                          fontSize={14}
                          padding={5}
                          width={'100%'}
                          bntType="primary"
                          mode="contained"
                          text="CREATE"
                          // loading={authLoading}
                          onPress={onSubmit}
                        />
                      </View>
                    </View>
                  </View>
                </ScrollView>
              ) : (
                <>
                  <FlatList
                    contentContainerStyle={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 40,
                    }}
                    ListHeaderComponent={
                      <>
                        {productNameGroupData?.length < 1 && (
                          <Text style={{ fontSize: 15, fontWeight: '900' }}>No Product Name Group Create Yet</Text>
                        )}
                      </>
                    }
                    keyExtractor={(item) => item.id}
                    numColumns={4}
                    renderItem={({ item }) => (
                      <View style={{ margin: 10, borderRadius: 15 }}>
                        <View
                          style={{
                            backgroundColor: item.is_archived == 1 ? 'gray' : 'rgba(255, 255, 255, 1)',
                            height: 200,
                            width: 250,
                            marginBottom: 5,
                            marginTop: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 15,
                            padding: 15,
                          }}
                        >
                          <View style={{ flexDirection: 'row-reverse' }}>
                            <Menu
                              visible={visible && item.id == productNameGroupParamsData.id}
                              onDismiss={closeMenu}
                              anchor={
                                <TouchableOpacity onPress={() => openMenu(item)}>
                                  <Text
                                    style={{
                                      textAlign: 'right',
                                      fontSize: 20,
                                      fontWeight: '900',
                                      color: appColors.primary,
                                    }}
                                  >
                                    ...
                                  </Text>
                                </TouchableOpacity>
                              }
                            >
                              <Menu.Item onPress={onOpenEditGroupNameModal} title="Edit" />
                              <Divider />
                              <Menu.Item onPress={showDeleteProductGroupNameModal} title="Delete" />
                              <Divider />
                              <Menu.Item
                                onPress={showArchiveProductGroupNameModal}
                                title={productNameGroupParamsData.is_archived == 1 ? 'Unarchive' : 'Archive'}
                              />
                            </Menu>
                          </View>
                          <View style={{ marginTop: 10, height: 40 }}>
                            <Text
                              numberOfLines={2}
                              ellipsizeMode="tail"
                              style={{
                                color: appColors.primary,
                                fontSize: 13,
                                fontWeight: '600',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.product_name_group}
                            </Text>
                          </View>

                          <Text
                            style={{
                              color: appColors.primary,
                              fontSize: 15,
                              fontWeight: '600',
                              textTransform: 'uppercase',
                              marginTop: 10,
                            }}
                          >
                            {org?.country?.currency_symbol}
                            {item.price}
                          </Text>
                          <View style={{ marginTop: 20 }}>
                            <CustomButton
                              text="View Product"
                              onPress={() => navigation.navigate('productScreen', { productNameGroupParamsData: item })}
                              mode="outlined"
                              bntType="primary"
                            />
                          </View>
                        </View>
                      </View>
                    )}
                    data={productNameGroupData}
                  />
                </>
              )}
            </View>
            <CustomModal
              content={
                <>
                  <div style={{ overflow: 'auto', height: '300px' }}>
                    <View style={{ width: '100%' }}>
                      <CustomInput
                        value={productNameGroupInfoEdit.product_name_group}
                        placeholder="Product Group Name"
                        label="Product Group Name"
                        onChangeText={(e) =>
                          setProductNameGroupInfoEdit({ ...productNameGroupInfoEdit, product_name_group: e })
                        }
                        inputMode="text"
                      />
                    </View>
                    <View style={{ width: '100%', marginTop: 30 }}>
                      <CustomInput
                        value={productNameGroupInfoEdit.price}
                        placeholder="Product Group Price"
                        label="Product Group Price"
                        onChangeText={(e) => setProductNameGroupInfoEdit({ ...productNameGroupInfoEdit, price: e })}
                        inputMode="numeric"
                      />
                    </View>
                    <View style={{ marginTop: 30 }}>
                      <CustomButton
                        fontSize={14}
                        padding={5}
                        width={'100%'}
                        bntType="primary"
                        mode="contained"
                        text="UPDATE"
                        // loading={authLoading}
                        onPress={onUpdateProductGroupName}
                      />
                    </View>
                  </div>
                </>
              }
              marginLeft={500}
              marginRight={500}
              hideModal={onCloseEditGroupNameModal}
              visible={editGroupNameModal}
            />
            <CustomDialog />
            <Portal>
              <Dialog
                style={{ marginRight: 500, marginLeft: 500 }}
                visible={archiveProductGroupNameModal}
                onDismiss={hideArchiveProductGroupNameModal}
              >
                <Dialog.Title style={{ color: 'green' }}>
                  {productNameGroupParamsData.is_archived == 1 ? 'Unarchive Group' : 'Archive Group'}
                </Dialog.Title>
                <Dialog.Content>
                  Do you want to {productNameGroupParamsData.is_archived == 1 ? 'Unarchive Group' : 'Archive Group'}{' '}
                  this product group?
                </Dialog.Content>
                <Dialog.Actions>
                  <Button onPress={hideArchiveProductGroupNameModal}>Cancel</Button>
                  <Button
                    style={{ backgroundColor: 'green', width: 80 }}
                    onPress={() =>
                      archiveProductGroupName(
                        productNameGroupParamsData.id,
                        productNameGroupParamsData.product_category_id,
                        hideArchiveProductGroupNameModal,
                      )
                    }
                  >
                    <Text style={{ color: '#ffffff' }}>Yes</Text>
                  </Button>
                </Dialog.Actions>
              </Dialog>
            </Portal>
            <Portal>
              <Dialog
                style={{ marginRight: 500, marginLeft: 500 }}
                visible={deleteProductGroupNameModal}
                onDismiss={hideDeleteProductGroupNameModal}
              >
                <Dialog.Title style={{ color: 'red' }}>Delete Group</Dialog.Title>
                <Dialog.Content>Do you want to delete this product group?</Dialog.Content>
                <Dialog.Actions>
                  <Button onPress={hideDeleteProductGroupNameModal}>Cancel</Button>
                  <Button
                    style={{ backgroundColor: 'red', width: 80 }}
                    onPress={() =>
                      deleteProductGroupName(
                        productNameGroupParamsData.id,
                        productNameGroupParamsData.product_category_id,
                        hideDeleteProductGroupNameModal,
                      )
                    }
                  >
                    <Text style={{ color: '#ffffff' }}>Yes</Text>
                  </Button>
                </Dialog.Actions>
              </Dialog>
            </Portal>
          </>
        }
      />
    </>
  );
};

export default ProductNameScreen;

import { getAsyncStorage, setAsyncStorage } from '../global/utils/asyncFun';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../global/appState/store';
import { setProfile, setShop, setOrg } from '../global/appState/slice/orgSlice'

const useOrg = () => {
  const { org, shopData, profileData }: any = useSelector((state: RootState) => state.org);
  const dispatch = useDispatch();

  const getOrgData = async () => {
    const data = await getAsyncStorage('org');
    console.log('org', data);
    dispatch(setOrg(data));
  };

  const getShopData = async () => {
    const data = await getAsyncStorage('shop');
    console.log('shop', data);
    dispatch(setShop(data));
  };

  const getProfileData = async () => {
    const data = await getAsyncStorage('profile');
    console.log('profile', data);
    dispatch(setProfile(data));
  };

  const onChangeShop = (val: any) => {
    setAsyncStorage('shop', val);
    toast.success(`switched shop to ${val.shop_name}`, {
      position: 'top-right',
    });
  };

  return {
    getOrgData,
    getShopData,
    onChangeShop,
    getProfileData,
    org,
    shopData,
    profileData,
  };
};

export default useOrg;

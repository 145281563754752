import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { CartScreen } from '../screens/till';
import {
  LobbyScreen,
  UsersScreen,
  CreateUserScreen,
  ProductScreen,
  CategoryScreen,
  ProductNameScreen,
  AddProductManuallyScreen,
  AddProductByScannerScreen,
  UserLogScreen,
  TransactionScreen,
  InventoryScreen,
  StockRecordScreen,
  ShopScreen
} from '../screens/admin';

type AppScreenProps = {
  cartScreen: undefined;
  lobbyScreen: undefined;
  usersScreen: undefined;
  createUserScreen: undefined;
  productScreen: undefined;
  categoryScreen: undefined;
  productNameScreen: undefined;
  addProductManuallyScreen: undefined;
  addProductByScannerScreen: undefined;
  userLogScreen: undefined;
  transactionScreen: undefined;
  inventoryScreen: undefined;
  stockRecordScreen: undefined;
  shopScreen: undefined
};

export type CartScreenProps = NativeStackScreenProps<AppScreenProps, 'cartScreen'>;
export type LobbyScreenProps = NativeStackScreenProps<AppScreenProps, 'lobbyScreen'>;
export type UsersScreenProps = NativeStackScreenProps<AppScreenProps, 'usersScreen'>;
export type CreateUserScreenProps = NativeStackScreenProps<AppScreenProps, 'createUserScreen'>;
export type ProductScreenProps = NativeStackScreenProps<AppScreenProps, 'productScreen'>;
export type CategoryScreenProps = NativeStackScreenProps<AppScreenProps, 'categoryScreen'>;
export type ProductNameScreenProps = NativeStackScreenProps<AppScreenProps, 'productNameScreen'>;
export type AddProductManuallyScreenProps = NativeStackScreenProps<AppScreenProps, 'addProductManuallyScreen'>;
export type AddProductByScannerScreenProps = NativeStackScreenProps<AppScreenProps, 'addProductByScannerScreen'>;
export type UserLogScreenProps = NativeStackScreenProps<AppScreenProps, 'userLogScreen'>;
export type TransactionScreenProps = NativeStackScreenProps<AppScreenProps, 'transactionScreen'>;
export type InventoryScreenProps = NativeStackScreenProps<AppScreenProps, 'inventoryScreen'>;
export type StockRecordScreenProps = NativeStackScreenProps<AppScreenProps, 'stockRecordScreen'>;
export type ShopScreenProps = NativeStackScreenProps<AppScreenProps, 'shopScreen'>;
ShopScreen

const Stack = createNativeStackNavigator<AppScreenProps>();

const AppNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        statusBarTranslucent: true,
        headerShown: false,
      }}
    >
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="cartScreen"
        component={CartScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="lobbyScreen"
        component={LobbyScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="usersScreen"
        component={UsersScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="createUserScreen"
        component={CreateUserScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="productScreen"
        component={ProductScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="categoryScreen"
        component={CategoryScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="productNameScreen"
        component={ProductNameScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="addProductManuallyScreen"
        component={AddProductManuallyScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="addProductByScannerScreen"
        component={AddProductByScannerScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="userLogScreen"
        component={UserLogScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="transactionScreen"
        component={TransactionScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="inventoryScreen"
        component={InventoryScreen}
      />

      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="stockRecordScreen"
        component={StockRecordScreen}
      />
      <Stack.Screen
        options={{ statusBarTranslucent: true, statusBarStyle: 'auto' }}
        name="shopScreen"
        component={ShopScreen}
      />
    </Stack.Navigator>
  );
};

export default AppNavigation;

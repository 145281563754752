export const PASSCODE_LOGIN = '/api/passcode/login';
export const ADMIN_LOGIN = '/api/app/admin/login';
export const CREATE_USER = '/api/shop/cashier/registration';
export const GET_USER = '/api/users/list';
export const GET_ROLES = '/api/roles';
export const CREATE_CATEGORY = '/api/product/category/create';
export const GET_CATEGORY = '/api/product/category/list';
export const UPDATE_CATEGORY = '';
export const CREATE_PRODUCT_GROUP = '/api/product/name/group/store';
export const UPDATE_PRODUCT_GROUP = '';
export const GET_PRODUCT_GROUP = '/api/product/name/group/list';
export const GET_ALL_PRODUCT_GROUP_FOR_LOCAL_DB = '/api/product/name/group/shop';
export const CREATE_PRODUCT_ITEM = '/api/product/store';
export const GET_PRODUCT_ITEM = '/api/product/list';
export const GET_ALL_SHOP_PRODUCT = '/api/product/shop';
export const SUBMIT_ORDER = 'api/order/create';
export const CHANGE_USER_STATUS = 'api/users/status';
export const EDIT_USER = 'api/users/update';
export const GET_USER_LOG = 'api/users/logs';
export const GET_TRANSACTION_LIST = 'api/order/list';
export const GET_RECEIPT = 'api/order/reciept';
export const GET_REFUND = 'api/order/find/invoice/barcode';
export const SUBMIT_ORDER_REFUND = 'api/order/refund';
export const GET_INVENTORY = 'api/product/inventory';
export const PRODUCT_UPDATE = 'api/product/stock/update';
export const GET_STOCK_RECORD = 'api/product/stock/record';
export const LOG_OUT = 'api/users/logout';
export const ADD_BARCODE_TO_PRODUCT = 'api/product/attach/barcode';
export const EDIT_PRODUCT_GROUP_NAME = 'api/product/name/group/update';
export const DELETE_PRODUCT_GROUP_NAME = 'api/product/name/group/delete';
export const ARCHIVE_PRODUCT_GROUP_NAME = 'api/product/name/group/archive';
export const DELETE_PRODUCT = 'api/product/delete';
export const OFFLINE_SYNC_ORDER = 'api/order/offline/transaction'
export const EDIT_PRODUCT = 'api/product/update'



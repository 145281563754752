import { View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { Card, RadioButton } from 'react-native-paper';
import { Svg, Path } from 'react-native-svg';
import { appColors } from '../global/constant/colors';
import { useState, useEffect, useRef } from 'react';
import useCart from '../hooks/useCart';
import { setAsyncStorage, getAsyncStorage } from '../global/utils/asyncFun';
import useOrg from '../hooks/useOrg';
import { Chip } from 'react-native-paper';
import { calculateTotalPrice } from '../global/utils/discountCalculator';

interface IProps {
  barcode: string;
  is_single: number;
  price: string;
  product_category_description: string;
  product_category_id: number;
  product_category_name: string;
  product_category_url: null;
  product_description: string;
  product_id: number;
  product_name: string;
  product_name_group: string;
  product_name_group_id: number;
  stock_id: number;
  stock_remaining: number;
  quantity: any;
  is_sold: number;
  name: string;
  product_status: string;
  stock_value: any;
  quantity_refunded: number;
  sub_total: string;
}

const CustomCartProductCard = ({ data, onShowQtyOutModalState }: any) => {
  let val: any = data;
  const { removeFromCartItem, setCartItemQty, setRefundData, undoRefundData, refundCheckOutData, setPriceType } =
    useCart();
  const { getOrgData, org } = useOrg();

  useEffect(() => {
    getOrgData();
  }, []);

  const onSetQtyVal = async () => {
    if (val?.stock_value != 1 && Number(val?.sub_total) != 0) {
      setAsyncStorage('_cartItemID', val?.product_id);
      onShowQtyOutModalState(val);
    }
  };

  const [checked, setChecked] = useState('price');
  const onsetPriceType = (type: string) => {
    setPriceType(val?.product_id, type);
    setChecked(type);
  };

  const priceShowCheck = () => {
    if ((val?.wholesale_price === 0 && val?.discount_price === 0) || val?.discount_price !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const discount: any = calculateTotalPrice(
    Number(val?.quantity ?? 1),
    Number(val?.price ?? 0),
    Number(val?.discount_price ?? 0),
    Number(val?.discount_unit ?? 0),
  );

  return (
    <>
      {val?.discount_price != 0 && (
        <Chip
          textStyle={{ textAlign: 'center', color: '#ffffff', fontWeight: 'bold' }}
          style={{
            width: 270,
            backgroundColor: '#228B22',
            marginBottom: -20,
            zIndex: 100,
            borderBottomStartRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          Discounted, Buy {val?.discount_unit} for {org?.country?.currency_symbol}
          {val?.discount_price}
        </Chip>
      )}
      <Card
        style={{
          backgroundColor: '#ffffff',
          height: 75,
          marginTop: 5,
          marginBottom: 5,
          borderColor: val?.quantity == 0 ? 'red' : '#ffffff',
          borderWidth: 3,
        }}
        mode="elevated"
      >
        <Card.Content
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}
        >
          {/* <View style={{ width: 50, height: 50, backgroundColor: 'rgba(217, 211, 222, 1)', borderRadius: 10 }}></View> */}
          <Image style={{ width: 30, height: 30, borderRadius: 10 }} source={require('../assets/itembox.png')} />

          <Text
            numberOfLines={2}
            ellipsizeMode="tail"
            style={{
              fontWeight: 'bold',
              fontSize: 13,
              color: appColors.primary,
              width: 120,
              textAlign: 'left',
              textTransform: 'capitalize',
            }}
          >
            {val?.is_sold !== undefined ? val?.name : val?.product_name}
          </Text>
          <TouchableOpacity
            style={{
              borderWidth: 3,
              borderColor: 'rgba(30, 30, 30, 0.3)',
              backgroundColor:
                val.product_status !== null
                  ? val?.stock_value === 1
                    ? 'rgba(30, 30, 30, 0.1)'
                    : '#ffffff'
                  : '#ffffff',
              borderRadius: 5,
              padding: 7,
              width: 90,
            }}
            onPress={() => onSetQtyVal()}
          >
            <Text
              style={{ fontWeight: 'bold', fontSize: 13, marginTop: 2, color: appColors.primary, textAlign: 'center' }}
            >
              {val?.quantity} {val.stock_value == 'kilogram' ? 'kg' : 'pcs'}
            </Text>
          </TouchableOpacity>

          <View>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', marginRight: '15px' }}>
                <View>
                  <RadioButton
                    value="first"
                    status={checked === 'price' ? 'checked' : 'unchecked'}
                    onPress={() => onsetPriceType('price')}
                  />
                </View>
                <View>
                  <Text style={{ fontWeight: 'bold', fontSize: 13, color: appColors.primary }}>Price</Text>{' '}
                  <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 2, color: appColors.primary }}>
                    {org?.country?.currency_symbol}
                    {val?.discount_price !== 0
                      ? discount.toFixed(2)
                      : (parseFloat(val?.price) * Number(val?.quantity))?.toFixed(2)}
                  </Text>
                </View>
              </div>
              <div style={{ display: 'flex' }}>
                <RadioButton
                  value="first"
                  status={checked === 'wholesale' ? 'checked' : 'unchecked'}
                  disabled={priceShowCheck()}
                  onPress={() => onsetPriceType('wholesale')}
                />
                <div>
                  <View>
                    <Text
                      style={{ fontWeight: 'bold', fontSize: 13, color: priceShowCheck() ? 'grey' : appColors.primary }}
                    >
                      Wholesale Price
                    </Text>
                    <Text
                      style={{
                        fontWeight: '700',
                        fontSize: 13,
                        marginTop: 2,
                        color: priceShowCheck() ? 'grey' : appColors.primary,
                      }}
                    >
                      {org?.country?.currency_symbol}
                      {(parseFloat(val?.wholesale_price) * Number(val?.quantity))?.toFixed(2)}
                    </Text>
                  </View>
                </div>
              </div>
            </div>
          </View>

          {val?.is_sold !== undefined ? (
            <>
              {refundCheckOutData?.products?.find((data: any) => data.product_id == val?.product_id) ? (
                <TouchableOpacity
                  onPress={() => undoRefundData(val?.product_id)}
                  style={{
                    backgroundColor: 'green',
                    padding: 15,
                    borderRadius: 10,
                    width: '12%',
                    marginRight: 10,
                  }}
                >
                  <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700', fontSize: 12 }}>Undo</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => setRefundData(val)}
                  style={{
                    backgroundColor: 'rgba(183, 35, 0, 1)',
                    padding: 15,
                    borderRadius: 10,
                    width: '12%',
                    marginRight: 10,
                  }}
                >
                  <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700', fontSize: 12 }}>Refund</Text>
                </TouchableOpacity>
              )}
            </>
          ) : (
            <TouchableOpacity onPress={() => removeFromCartItem(val?.product_id)}>
              <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <Path
                  d="M31.6666 10.6835L29.3166 8.3335L19.9999 17.6502L10.6833 8.3335L8.33325 10.6835L17.6499 20.0002L8.33325 29.3168L10.6833 31.6668L19.9999 22.3502L29.3166 31.6668L31.6666 29.3168L22.3499 20.0002L31.6666 10.6835Z"
                  fill="#1E1E1E"
                />
              </Svg>
            </TouchableOpacity>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

export default CustomCartProductCard;

import { createSlice } from '@reduxjs/toolkit';

// Define the reducer logic for 'twoSlice'
const customTransactionSlice = createSlice({
  name: 'transactionState',
  initialState: {
    transactionData: [],
    receiptData: {},
    totalSum: "",
    estimated_profit: ""

  },
  reducers: {
    setTransactionData(state, action) {
      state.transactionData = action.payload;
    },

    setTransactionTotalSumData(state, action) {
      state.totalSum = action.payload;
    },

    setTransactionTotalProfitData(state, action) {
      state.estimated_profit = action.payload;
    },

    setReceiptData(state, action) {
      state.receiptData = action.payload;
    },
    
  },
});

export const { setTransactionData, setReceiptData, setTransactionTotalSumData, setTransactionTotalProfitData } = customTransactionSlice.actions;

export default customTransactionSlice;

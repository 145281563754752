import { createSlice } from '@reduxjs/toolkit';

const orgSlice = createSlice({
  name: 'orgState',
  initialState: {
    org: {},
    shopData: {},
    profileData: {},
  },
  reducers: {
    setOrg(state, action) {
      state.org = action.payload;
    },
    setProfile(state, action) {
      state.profileData = action.payload;
    },
    setShop(state, action) {
      state.shopData = action.payload;
    },
  },
});

export const { setProfile, setShop, setOrg } = orgSlice.actions;

export default orgSlice;

import { View, TouchableOpacity, Text, TextInput } from 'react-native';
import { CustomButton } from '../../components';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import { StockRecordScreenProps } from '../../navigation/appNavigation';
import { FC, useEffect } from 'react';
import { DataTable } from 'react-native-paper';
import { useState } from 'react';
import useTransaction from '../../hooks/useTransaction';
import { Chip } from 'react-native-paper';
import moment from 'moment';
import useOrg from '../../hooks/useOrg';
import useProduct from '../../hooks/useProduct';

const StockRecordScreen: FC<StockRecordScreenProps> = ({ navigation, route }) => {
  const { product }: any = route.params;
  const { getOrgData, org } = useOrg();
  const { getStockRecord, getStockRecordByDate, stockRecordData } = useProduct();
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([20, 30, 50, 100]);
  const [itemsPerPage, onItemsPerPageChange] = useState(numberOfItemsPerPageList[0]);
  const [_date, _setDate] = useState<any>({
    date_from: '',
    date_to: '',
  });

  useEffect(() => {
    getStockRecord(product.stock_id);
  }, []);

  useEffect(() => {
    if (_date.date_from.length > 1 && _date.date_to.length > 1) {
      getStockRecordByDate(product?.stock_id, _date);
    }
  }, [_date]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, [].length);

  useEffect(() => {
    setPage(0);
    getOrgData();
  }, []);

  return (
    <>
      <AdminLayout
        content={
          <View>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <View style={{ flexDirection: 'row' }}>
                    <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                      <Path
                        d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                        fill="#1E1E1E"
                      />
                    </Svg>
                    <Text
                      style={{
                        fontWeight: '700',
                        color: 'rgba(30, 30, 30, 1)',
                        marginLeft: 10,
                        textTransform: 'uppercase',
                      }}
                    >
                      {product.product_name_group} STOCK RECORD
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginLeft: 'auto' }}>
                <View style={{ flexDirection: 'row' }}>
                  {/* <View style={{ marginRight: 10, marginTop: 20 }}>
                    <TextInput
                      textAlign="center"
                      textAlignVertical="center"
                      keyboardAppearance="default"
                      placeholder="Search"
                      style={{
                        height: 50,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 400,
                        backgroundColor: '#efeded',
                      }}
                      // value={searchQuery}
                      // onChangeText={setSearchQuery}
                    />
                  </View> */}
                  <View style={{ marginRight: 10 }}>
                    <Text>Date From</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_from: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>
                  <View style={{ marginRight: 10 }}>
                    <Text>Date To</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_to: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>

                  {/* <View style={{ marginTop: 16 }}>
                    <CustomButton
                      fontSize={14}
                      padding={5}
                      width={200}
                      bntType="primary"
                      mode="contained"
                      text="ADD CASHIER"
                      onPress={() => navigation.navigate('createUserScreen')}
                    />
                  </View> */}
                </View>
              </View>
            </View>

            <DataTable style={{ backgroundColor: '#ffffff', borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
              <DataTable.Header>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Created By</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>New Stock</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Old Stock</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Quantity sold</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Type</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Created Date</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Updated Date</DataTable.Title>
              </DataTable.Header>

              {stockRecordData?.map((item: any) => (
                <DataTable.Row key={item.id}>
                  <DataTable.Cell>{item?.created_by}</DataTable.Cell>
                  <DataTable.Cell>{item?.new_stock_count}</DataTable.Cell>
                  <DataTable.Cell>{item?.old_stock_count}</DataTable.Cell>
                  <DataTable.Cell>{item?.sold_quantity == null ? '0' : item?.sold_quantity}</DataTable.Cell>
                  <DataTable.Cell>
                    <Chip
                      style={{
                        backgroundColor: item?.type == 'Sale' ? 'green' : item?.type == 'Refund' ? 'red' : '#000000',
                      }}
                      textStyle={{ color: '#ffffff', textAlign: 'center', justifyContent: 'center' }}
                    >
                      {item?.type}
                    </Chip>
                  </DataTable.Cell>
                  <DataTable.Cell>{moment(item.created_at).format('YYYY-MM-DD')}</DataTable.Cell>
                  <DataTable.Cell>{moment(item.updated_at).format('YYYY-MM-DD')}</DataTable.Cell>
                </DataTable.Row>
              ))}

              {/* <DataTable.Pagination
                page={transactionData?.current_page}
                numberOfPages={transactionData?.last_page}
                onPageChange={(page) => onChangePage(page)}
                label={`${from + 1}-${to} of ${transactionData?.total}`}
                numberOfItemsPerPageList={numberOfItemsPerPageList}
                // numberOfItemsPerPage={itemsPerPage}
                // onItemsPerPageChange={(val) => onChnagePerpage(val)}
                selectPageDropdownLabel={'Rows per page'}
              /> */}
            </DataTable>
          </View>
        }
      />
    </>
  );
};

export default StockRecordScreen;
